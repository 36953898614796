import { getAllWorkflows } from "../../pages/StudioV2/utils/api";

// Exports:
export const studioActions = {
  SET_SELECTED_NODE: 'SET_SELECTED_NODE',
  SET_WORKFLOWS:'SET_WORKFLOWS',
  GET_WORKFLOWS_BEGIN:'GET_WORKFLOWS_BEGIN'
};

export const setSelectedNode = (payload) => ({
  type: studioActions.SET_SELECTED_NODE,
  payload
});

export const setWorkflowss = (payload) => ({
  type: studioActions.SET_WORKFLOWS,
  payload
});

export const fetchWorkflows = (org_id,owner,agent_type) => {
  return async (dispatch) => {
    dispatch({ type: studioActions.GET_WORKFLOWS_BEGIN });
    try {
      const workflows = await getAllWorkflows(org_id,owner,agent_type)
      const fetchedWorkflows=workflows?.data?.Workflows
      fetchedWorkflows.sort(
        (workflowA, workflowB) =>
          workflowB.meta.lastEdited - workflowA.meta.lastEdited
      );
      dispatch({
        type: studioActions.SET_WORKFLOWS,
        payload:fetchedWorkflows
      });
    } catch (error) {
      dispatch({
        // type: ChatActions.FETCH_CHAT_RECORDS_ERROR,
        // payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const getStudioActions = (dispatch) => {
  return {
    fetchWorkflows: (payload, owner,agent_type) => dispatch(fetchWorkflows(payload, owner,agent_type)),
  }
}