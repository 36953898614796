// Packages:
import React, { memo, useEffect, useState } from "react";
import { FormGenerator } from "../../../../pages";
import useInput from "../../../../lib/hooks/use-input";
import BaseConfiguration from "../BaseConfiguration";

// Functions:
const MessageConfiguration = ({ node, updateNode, deleteNode, isEditable, setNodes }) => {
  // State:
  const {
    value: formName,
    setValue: setFormName,
    bind: bindFormName,
  } = useInput(node.formName ?? "");
  const {
    value: formDescription,
    setValue: setFormDescription,
    bind: bindFormDescription,
  } = useInput(node.formDescription ?? "");

  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields]=useState([])

  // Effects:
  useEffect(() => {
    setFormName(node.formName);
    setFormDescription(node.formDescription);
  }, [node.formName, node.formDescription]);

  useEffect(() => {
    updateNode(node.id, {
      formName,
      formDescription,
      dynamicFields: dynamicFields,
      outputFields:outputFields
    });
  }, [formName, formDescription, dynamicFields]);

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FormGenerator
        bindFormName={bindFormName}
        bindFormDescription={bindFormDescription}
        isEditable={isEditable}
      />
    </BaseConfiguration>
  );
};

// Exports:
export default memo(MessageConfiguration)
