// Packages:
import React, { memo } from 'react';
import { useSelector } from 'react-redux';


// Styles:
import { Body, Description, Field, NodeLabel, NodeWrapper, StyledHandle } from '../styles';
import { config } from './config';
import BaseNode from '../BaseNode';
import useNodeStyles from '../../../../hooks/useNodeStyles';


// Functions:
const TicketNode = ({ data }) => {
  // Constants:
  const { id, ticketChannel, subject, label } = data;
  const isSelected = useSelector(
    (state) => state.studio.selectedNode?.id === id
  );

  const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})

  // State:
  const studio = useSelector((state) => state.studio);
  // Return:
  return (
<BaseNode data={data} config={config}>
    <NodeWrapper
      style={computedWrapperStyle}
      backgroundColor={'#FEFFFE'}
      borderColor={'#235789'}
    >
       {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
      <NodeLabel
        htmlFor="text"
        style={computedLabelStyle}
      >
        {label}
      </NodeLabel>
      <Body
        style={computedBodyStyle}
      >
        <Description>
          <Field isActive={studio.selectedNode?.id === id} activeColor={'#112E49'} inActiveColor={'#235789'}>
            {!ticketChannel ? (
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                Ticket Channel
              </span>
            ) : (
              ticketChannel
            )}
          </Field>
        </Description>
        <Description>
        <Field isActive={studio.selectedNode?.id === id} activeColor={'#112E49'} inActiveColor={'#235789'}>
          {(subject?.value?.fieldName !== null || subject?.value?.value !== null) ? (
             `${subject?.value?.fieldName ?? ''} ${subject?.value?.value ?? ''}`
               ) : (
             <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                  Write a subject...
             </span>
           )}
        </Field>
        </Description>
      </Body>
    </NodeWrapper>
    </BaseNode>   
  );
};

// Exports:
export default memo(TicketNode);
