import React, { useRef, useState } from "react";
import { StyledDiv } from "../styles";

const UploadJsonFile = ({ fileData, setFileData, isEditable }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = () => {
        try {
          const content = JSON.parse(reader.result);
          setFileData(content);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  //   TODO try this once
  //   const obj = { hello: "world" };
  // const blob = new Blob([JSON.stringify(obj, null, 2)], {
  //   type: "application/json",
  // });

  return (
    <StyledDiv>
      <StyledDiv
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "2rem",
        }}
      >
        <text style={{ fontWeight: "bold" }}>Upload: </text>
        <input
          type="file"
          disabled={!isEditable}
          accept=".json"
          onChange={handleFileChange}
          style={{ display: "none" }}
          value={""}
          ref={fileInputRef}
        />
        <button
          style={{ padding: "4px" }}
          onClick={() => fileInputRef.current.click()}
        >
          Credential
        </button>
      </StyledDiv>
      {fileData && (
        <div style={{ fontWeight: "bold", color: "green" }}>
          Successfully Uploaded !
        </div>
      )}
    </StyledDiv>
  );
};

export default UploadJsonFile;
