// Packages:
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  IconButton,
  Tooltip,
  OutlinedInput,
  InputAdornment,
  FormControl,
  styled
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import OpenAI from 'openai';

// Redux:
import {
  addConversation,
  chatStarted,
  setBotTyping,
  startWorkflowExecution,
  stopWorkflowExecution,
  renderDynamicForm
} from '../../../redux/actions/chatActions';
import {
  getContentActions,
  updateQueryInStore
} from '../../../redux/actions/contentActions';
import {
  getAuthActions,
  setAgentCustomerChatStatus
} from '../../../redux/actions/authActions';

// Styles:
import { Wrapper } from './styles';
import { WebSocketConnectionContext } from '../../../App';

// const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true })
const openai = new OpenAI({ apiKey: "test", dangerouslyAllowBrowser: true })

const StyledFormControl = styled(FormControl)`
  font-size: 14px;
  & .MuiOutlinedInput-root {
    height: 3rem;
    font-size: 14px;
    background: #ffffff;

    @media (max-width: 520px) {
      font-size: 16px;
    }
  }
`;

// NOTE: This includes just the input box for the user to type reply
// Functions:

const Reply = (props) => {
  // Constants:
  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);
  const authActions = getAuthActions(dispatch);
  const organisation_info = useSelector((state) => state.content.org_info);
  const userInfo = useSelector((state) => state.auth.user);
  const agentType = useSelector((state) => state.content.agent_type);
  const agentPersonas = useSelector((state) => state.content.agent_personas);
  const chatMedium = organisation_info?.org_data?.chatMedium;

  // State:
  const content = useSelector((state) => state.content);
  const chat = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth);
  const userDetails = useSelector((state) => state.auth.userDetails);
  //console.log(auth)
  const [reply, setReply] = useState('');
  const [speech, setSpeech] = useState(null);
  const orgid = organisation_info?.org_data?._id;
  const { openConnection, changeConnection, sendJsonMessage } = useContext(
    WebSocketConnectionContext
  );

  const [isListening, setIsListening] = useState(false);
  const [voiceText, setVoiceText] = useState("");
	const mediaRecorder = useRef(null);
	const [audioChunks, setAudioChunks] = useState([]);

  // Functions:
  const openWebsocketConnection = useCallback(() => {
    // console.log('click connect', openConnection);
    dispatch(
      addConversation({
        type: 'bot',
        subtype: 'received',
        message: 'Please wait, connecting you to a live agent!'
      })
    );
    if (openConnection === false) changeConnection(true);
    let setTypeMessage = {
      action: 'setType',
      orgid: orgid,
      type: 'endUser',
      userId: userInfo.id
    };

    if (userDetails?.email) {
      setTypeMessage['userEmail'] = userDetails?.email;
    }

    // console.log(
    //   'sending this as customer to open ws connection',
    //   setTypeMessage
    // );
    sendJsonMessage(setTypeMessage);
    dispatch(setAgentCustomerChatStatus(true));
  }, [
    changeConnection,
    dispatch,
    openConnection,
    orgid,
    sendJsonMessage,
    userDetails?.email,
    userInfo.id
  ]);

  const handleKeyPress = useCallback(
    (e) => {
      if (
        reply.trim().length > 0 &&
        (e.key === 'Enter' || e.type === 'click')
      ) {
        dispatch(chatStarted());
      }
    },
    [dispatch, reply]
  );

  const startRecording = async () => {
    try{
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      const media = new MediaRecorder(mediaStream, { mimeType : "audio/webm" });

      // Commented Code is for the feature of Automatic mic off when Silence is Detected, but this is a bit buggy and doesn't support all browsers, should I keep it, for future?

      // const audioContext = new (window.AudioContext || window.webkitAudioContext)(); 
      // const analyser = audioContext.createAnalyser();
      // const bufferSize = 2048;
      // const dataArray = new Uint8Array(bufferSize);
      // analyser.fftSize = bufferSize;

      // const sourceNode = audioContext.createMediaStreamSource(mediaStream);
      // sourceNode.connect(analyser);

      mediaRecorder.current = media;
      mediaRecorder.current.start();

      // const checkSilence = () => {
      //   analyser.getByteFrequencyData(dataArray);
      //   const average = dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;

      //   if (average>0 && average < 50) { // Adjust 
      //     // stopRecording();
      //     console.log("silence detected");
      //   } else {
      //     setTimeout(checkSilence, 2000); 
      //   }
      // };

      // checkSilence();

      let localAudioChunks = [];

      mediaRecorder.current.ondataavailable = (event) => {
        try{
          if (!event?.data?.size) return; 
          localAudioChunks.push(event.data);
        }
        catch(error){
          console.error("Error processing audio data:", error);
        }
        finally{
          mediaRecorder.current.ondataavailable = null
        }
      };
      setAudioChunks(localAudioChunks);
    }
    catch(error){
      console.error("Error starting recording:", error);
    }
	};

	const stopRecording = () => {
    try{
      if (mediaRecorder.current) {  
        if (mediaRecorder.current.stream) {  
          mediaRecorder.current.stream.getTracks().forEach(track => track.stop());  
        }  
      }
      mediaRecorder.current.stop();

      mediaRecorder.current.onstop = async () => {
        try{
          // console.log("inside stop recording", audioChunks)
          const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
          const webmFile = new File([audioBlob], 'audio.webm', { type: 'audio/webm' });

          const translation = await openai.audio.translations.create({
            file: webmFile,
            model: "whisper-1",
          });

          setReply(translation.text);
          setVoiceText(translation.text);
          setAudioChunks([]);
        }
        catch(error){
          console.error("Error processing audio translation:", error);
        }
        finally{
          mediaRecorder.current.onstop = null
        }
      };
    }
    catch(error){
      console.error("Error stopping recording:", error);
    }
	};

  const handleMicPress = () => {
    if(!speech){
      if(isListening){
        stopRecording();
      }
      else{
        startRecording();
      }
    }
    else{
      if (isListening) {
        speech.stop();
      } else {
        speech.start();
      }
    }
    setIsListening(!isListening);
  };


  useEffect(()=>{
    if (window.webkitSpeechRecognition) {
      // eslint-disable-next-line
      const SpeechRecognition = window.webkitSpeechRecognition;
      let newSpeech = new SpeechRecognition();
      newSpeech.continuous = true;

      newSpeech.onresult = (event) => {
            setReply(event.results[event.results.length - 1][0].transcript);
            setVoiceText(event.results[event.results.length - 1][0].transcript);
      };
      newSpeech.onerror = (event) => {  
        console.error("Speech recognition error", event.error);  
      };  
      setSpeech(newSpeech)

      return () => {
        newSpeech.stop();
        newSpeech.onresult = null;
        newSpeech.onerror = null;
        };
    } else {
      console.log("webkit speech recognition not identified in the browser, trying other media recorders")
      setSpeech(null)
    }

  },[])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (e && e.preventDefault) { e.preventDefault() }
      // e.preventDefault();
      if(localStorage.getItem('unauthenticated_user') === null){
        dispatch(setBotTyping(true));
      }
      contentActions.renderWorkflowUrl(null);
      const typedString = reply.trim();
      setReply('');
      if (typedString.trim().length > 0) {
        dispatch(updateQueryInStore(typedString));

        if(typedString.toLowerCase()==='leave message' && (window.location.href).split('//')[1].split('.')[0].includes('dashlane'))
        {
          dispatch(renderDynamicForm(true));
        }
        else if (chat.agentChatWorkflowStarted) {
          dispatch(
            addConversation({
              type: userInfo?.role,
              subtype: 'sent',
              message: typedString
            })
          );
          const isEmailFieldActive = localStorage.getItem('isEamilFieldActive')==="true";
          if (typedString.includes('@')|| isEmailFieldActive) {
            if (
              !String(typedString)
                .toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
            ) {
              dispatch(
                addConversation({
                  type: 'bot',
                  subtype: 'received',
                  message: 'Enter a valid email address'
                })
              );
              dispatch(setBotTyping(false));
              localStorage.setItem('isEamilFieldActive', true)
            } else {
              localStorage.setItem('isEamilFieldActive', false)
              try {
                await authActions.signInUser(
                  String(typedString)?.toLowerCase(),
                  orgid
                );
              } catch (error) {
                console.error(error);
              }
            }
          } else if (
            !isNaN(typedString) &&
            typedString.length === 4 &&
            ((auth.Session !== null &&
            auth.Session !== undefined)||localStorage.getItem("isTemporaryUserActive")==='true')
          ) {
            try {
              await authActions.otpVerification(
                auth.email,
                auth.Session,
                typedString,
                organisation_info,
                auth.user,
                chatMedium === 'slack'
              );
              if (chatMedium === 'fasttrack') openWebsocketConnection();
            } catch (error) {
              console.error(error);
            }
          } else {
            if (!chatMedium || chatMedium === 'slack') {
              if (localStorage.getItem('slack_channel_id') !== null) {
                authActions.sendingMessage(typedString, auth.channelId);
              } else if (
                localStorage.getItem('validated_user') === null &&
                localStorage.getItem('unauthenticated_user') === null &&
                localStorage.getItem('slack_channel_id') === null
              ) {
                dispatch(
                  addConversation({
                    type: 'bot',
                    subtype: 'received',
                    message:
                      'Please enter your email and authenticate yourself.'
                  })
                );
              }
            } else {
              if (auth.agentCustomerChatOn) {
                const messageToSend = {
                  action: 'sendMessage',
                  orgid: orgid,
                  type: userInfo?.role,
                  message: typedString
                };
                sendJsonMessage(messageToSend);
              }
            }
          }
          dispatch(setBotTyping(false));
        } else if (!chat.agentChatWorkflowStarted && !chat.workflowStarted) {
          dispatch(
            addConversation({
              type: 'endUser',
              subtype: 'sent',
              message: typedString
            })
          );
          if (chat.workflow) {
            const res = await contentActions.fetchOrderStatus(typedString);
            dispatch(stopWorkflowExecution());
          } else {
            try {
              const data = {
                orgid,
                query: typedString,
                role: agentType ?? 'endUser',
                personas: agentPersonas ?? []
              };
              await sendJsonMessage({ action: 'interact', ...data });
            } catch (err) {
              console.error(err);
              addConversation({
                type: 'bot',
                subtype: 'received',
                message: "Sorry we couldn’t resolve your issue. Please click to connect with our support team."
              });
            }
          }
        } else if (chat.workflowStarted) {
          dispatch(
            addConversation({
              type: 'endUser',
              subtype: 'sent',
              message: typedString
            })
          );
          dispatch(setBotTyping(false));
          const replies_expected =
            typeof chat?.incomingMessage?.messageData?.replies_expected ===
            'string'
              ? JSON.parse(chat?.incomingMessage?.messageData?.replies_expected)
              : chat?.incomingMessage?.messageData?.replies_expected;
          const keyToSend = replies_expected?.[0]?.field;
          const sendMsg = {
            action: 'sendMessage',
            connected_to: chat?.incomingMessage?.messageData?.my_connection_id,
            orgid,
            type: 'stateMachineReply',
            message: {
              replies: {}
            }
          };
          sendMsg['message']['replies'][keyToSend] = typedString;
          sendJsonMessage(sendMsg);
        }
      }
    },
    [
      auth.Session,
      auth.agentCustomerChatOn,
      auth.channelId,
      auth.email,
      auth.user,
      authActions,
      chat.agentChatWorkflowStarted,
      chat?.incomingMessage?.messageData?.my_connection_id,
      chat?.incomingMessage?.messageData?.replies_expected,
      chat.workflow,
      chatMedium,
      chat.workflowStarted,
      contentActions,
      dispatch,
      openWebsocketConnection,
      organisation_info,
      orgid,
      reply,
      sendJsonMessage,
      userInfo?.role
    ]
  );

  useEffect(() => {
    if (content.orderStatus !== null) {
      contentActions.renderWorkflowUrl(content.orderStatus?.order_status_url);
      dispatch(setBotTyping(false));
    }
  }, [content.orderStatus]);

  // Return:
  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <StyledFormControl fullWidth variant="outlined">
          <OutlinedInput
            id="chat-reply"
            autoFocus={false}
            value={reply}
            placeholder="Please type your query here..."
            type="text"
            onKeyPress={(e) => !chat.chatOn && handleKeyPress(e)}
            onChange={(e) => {
              setReply(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
               {isListening? (
                  <Tooltip title="Mic-Off" style={{marginRight: '0.15rem'}}>
                    <IconButton
                      onClick={handleMicPress}
                      edge="end"
                      >
                      <MicIcon />
                    </IconButton>
                  </Tooltip>
                ): (
                  <Tooltip title="Microphone" style={{marginRight: '0.15rem'}}>
                    <IconButton
                      onClick={handleMicPress}
                      edge="end"
                      >
                      <MicOffIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="send">
                  <IconButton
                    onClick={(e) => handleKeyPress(e)}
                    type="submit"
                    edge="end"
                  >
                    <SendIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        </StyledFormControl>
      </form>
    </Wrapper>
  );
};

// Exports:
export default Reply;
