// Packages:
import React, { useState, useEffect, useCallback, memo, useMemo } from "react";
import uuid from "react-uuid";
import { TextField, styled, Button, Autocomplete } from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import FormInputs from "./FormInputs";
import useInput from "../../../../lib/hooks/use-input";

// Styles:
import { FieldName } from "../styles";
import BaseConfiguration from "../BaseConfiguration";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }

  & .MuiFormLabel-root {
    color: ${(props) =>
      props.messageErrorText === "Valid HTML" ? "green" : "default"};
  }

  & .MuiFormHelperText-root {
    color: ${(props) =>
      props.messageErrorText === "Valid HTML" ? "green" : "default"};
  }

  & fieldset {
    border: ${(props) =>
      props.messageErrorText === "Valid HTML" ? "1px solid green" : "default"};
  }
`;

// Constants:
const DEFAULT_OUTPUT = () => ({
  id: uuid(),
  field: "",
  type: "STRING",
  message: "",  
  validation:{isOptional:false}
});
// Functions:
const MessageConfiguration = ({ node, updateNode, deleteNode, isEditable,setNodes }) => {
  // State:
  const {
    value: message,
    setValue: setMessage,
    bind: bindMessage,
  } = useInput(node.message ?? "");
  const {
    value: formName,
    setValue: setFormName,
    bind: bindFormName,
  } = useInput(node.formName ?? "");
  const [expectResponse, setExpectResponse] = useState(node.expectResponse??false);
  const { value: responsesExpected, setValue: setResponsesExpected } = useInput(
    node.responsesExpected??0);
  const [outputs, setOutputs] = useState(node.outputs??[]);
  const [messageErrorText, setMessageErrorText] = useState("");
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields] = useState([])
  const [dynamicDataExpected, setDynamicDataExpected] = useState(
    node.dynamicDataExpected??false
  );
  const [numberOfVariables, setNumberOfVariables] = useState(
    node.numberOfVariables ?? 0
  );
  const [dynamicData, setDynamicData] = useState(node.dynamicData??[]);

  // Effects:
  useEffect(() => {
    setMessage(node.message??"");
    setFormName(node.formName??"");
    setExpectResponse(node.expectResponse??false);
    setResponsesExpected(node.responsesExpected??0);
    setOutputs(node.outputs??[]);
    setDynamicDataExpected(node.dynamicDataExpected??false);
    setNumberOfVariables(node.numberOfVariables ?? 0);
    setDynamicData(node.dynamicData ?? []);
  }, [
    node.message,
    node.formName,
    node.expectResponse,
    node.responsesExpected,
    node.outputs,
    node.dynamicDataExpected,
    node.numberOfVariables,
    node.dynamicData,
  ]);

  useEffect(() => {
    updateNode(node.id, {
      message,
      formName,
      expectResponse,
      responsesExpected,
      outputs,
      dynamicDataExpected,
      numberOfVariables,
      dynamicData,
      dynamicFields: dynamicFields,
      outputFields:outputFields
    });
  }, [
    message,
    formName,
    expectResponse,
    responsesExpected,
    outputs,
    dynamicFields,
    dynamicDataExpected,
    numberOfVariables,
    dynamicData,
  ]);

  const updateOutput = useCallback((value) => {
    setOutputs(value);
  },[]);

  const handleMessageValidate = useCallback(() => {
    const openingTags = message.match(/<[a-z]+?>/g);
    const closingTags = message.match(/<[/][a-z]+?>/g);
    if (openingTags === null && closingTags === null) setMessageErrorText("");
    else {
      if (
        (openingTags !== null || closingTags !== null) &&
        openingTags?.length !== closingTags?.length
      )
        setMessageErrorText("Invalid HTML");
      else setMessageErrorText("Valid HTML");
    }
  }, [node?.message]);

  const handleNumberOfInputChange = useCallback((e) => {
    const value = parseInt(e.target.value, 10) || 0;
    setNumberOfVariables(value);

    setDynamicData((prevDynamicData) => {
      const newDynamicData = [...prevDynamicData];
      if (value < newDynamicData.length) {
        newDynamicData.length = value;
      } else if (value > newDynamicData.length) {
        for (let i = newDynamicData.length + 1; i <= value; i++) {
          newDynamicData.push({
            key: null,
            value: { fieldName: null, value: null },
          });
        }
      }
      return newDynamicData;
    });
  }, []);

  const handleCheckboxChange = useCallback(() => {
    setDynamicDataExpected(!dynamicDataExpected);
    setNumberOfVariables(0);
    setDynamicData([]);
  }, [dynamicDataExpected]);

  const handleDataInputChange = useCallback(
    (index, field, value) => {
      if (field !== "key" && !dynamicFields.includes(value)) return;

      setDynamicData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [field === "key" ? "key" : "value"]:
            field === "key"
              ? value
              : { ...newData[index].value, fieldName: value },
        };
        return newData;
      });
    },
    [dynamicFields]
  );

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FieldName>Message</FieldName>
      <StyledTextField
        id="message"
        variant="outlined"
        disabled={!isEditable}
        error={
          messageErrorText.length === 0 || messageErrorText === "Valid HTML"
            ? false
            : true
        }
        label={
          messageErrorText.length === 0
            ? null
            : messageErrorText === "Valid HTML"
            ? "Success"
            : "Error"
        }
        helperText={messageErrorText}
        placeholder="Enter a message / HTML to be displayed to the user"
        {...bindMessage}
        multiline
        rows={5}
        size="small"
        fullWidth
        messageErrorText={messageErrorText}
      />
      <Button
        variant="contained"
        size="small"
        startIcon={<TaskAltOutlinedIcon />}
        sx={{ textTransform: "none", mb: "1rem" }}
        onClick={() => handleMessageValidate()}
      >
        Validate HTML
      </Button>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={dynamicDataExpected}
          onChange={handleCheckboxChange}
        />
        <div style={{ fontSize: "0.8rem" }}>dynamic fields expected:</div>
        <input
          type="number"
          min="0"
          disabled={!dynamicDataExpected || !isEditable}
          style={{
            width: "3rem",
            marginLeft: "0.25rem",
          }}
          value={numberOfVariables}
          onChange={handleNumberOfInputChange}
        />
      </div>
      {dynamicDataExpected && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {dynamicData.map((_, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <StyledTextField
                type="text"
                placeholder="Variable"
                disabled={!isEditable}
                value={dynamicData[index].key}
                size="small"
                style={{ padding: 0 }}
                onChange={(e) =>
                  handleDataInputChange(index, "key", e.target.value)
                }
              />
              <Autocomplete
                options={dynamicFields}
                disabled={!isEditable}
                value={dynamicData[index].value.fieldName}
                fullWidth
                onChange={(_, value) =>
                  handleDataInputChange(index, "fieldName", value)
                }
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Value"
                    variant="outlined"
                    size="small"
                    style={{ padding: 0 }}
                  />
                )}
              />
            </div>
          ))}
        </div>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={expectResponse}
          onChange={(e) => {
            if (!e.target.checked && expectResponse) {
              setOutputs([]);
              setResponsesExpected(0);
            }
            setExpectResponse(e.target.checked);
          }}
        />
        <div style={{ fontSize: "0.8rem" }}>Responses expected:</div>
        <input
          type="number"
          min="0"
          disabled={!expectResponse || !isEditable}
          style={{
            width: "3rem",
            marginLeft: "0.25rem",
          }}
          value={responsesExpected}
          onChange={(event) => {
            event.preventDefault();
            event.stopPropagation();
            const newOutputsLength = parseInt(event.currentTarget.value);
            setResponsesExpected(newOutputsLength);
            const currentOutputsLength = outputs.length;
            if (newOutputsLength === currentOutputsLength + 1)
              setOutputs([...outputs, DEFAULT_OUTPUT()]);
            if (newOutputsLength === currentOutputsLength - 1)
              setOutputs([...outputs].slice(0, -1));
          }}
        />
      </div>
      {expectResponse && (
        <>
          <div style={{ width: "100%", height: "1rem" }} />
          <FieldName>Form Name</FieldName>
          <StyledTextField
            id="message"
            variant="outlined"
            disabled={!isEditable}
            placeholder="Enter a form name"
            {...bindFormName}
            multiline
            size="small"
            fullWidth
          />
          <FieldName>Variables To Save</FieldName>
        </>
      )}
      {Object.values(outputs).map((output, i) => (
        <FormInputs
          key={output.id}
          outputs={outputs}
          isEditable={isEditable}
          output={output}
          i={i}
          updateOutput={updateOutput}
          dynamicFields={dynamicFields}
        />
      ))}
    </BaseConfiguration>
  );
};

// Exports:
export default memo(MessageConfiguration)
