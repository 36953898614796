export const VALID_URL_REGEX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/;
export const GPT_MISHIT_REGEX =
  /^(I'm not sure|I don't (know|understand)|Can you (rephrase|clarify)|I'm unsure|Please provide more information|The article does not|I can't (find|provide)|I could not (find|provide)|I couldn't (find|provide)|It's unclear).*/;
export const SUPPORTED_IFRAME_DOCS = /\.pdf(#page=\d+)?$/; // /\.(pdf|docx|xlsx)$/

export const MARKDOWN_LINKS = /\[([^\]]+)\]\(([^\)]+)\)/g;

export const RegexMapping = {
  DEFAULT_STRING: "/.*/ ",
  "XXX-XXX-XXXX": "^[A-Za-z0-9]{3}-[A-Za-z0-9]{3}-[A-Za-z0-9]{4}$",
  ALPHABETS:"/^[a-zA-Z]+$/",
  ALPHANUMERIC:"/^[a-zA-Z0-9]+$/",
  URLS:`/^(ftp|http|https):\/\/[^ "]+$/`
};
