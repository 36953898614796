// Packages:
import produce from 'immer';

// Actions:
import { studioActions } from '../actions/studioActions';

// Constants:
const initialState = {
  selectedNode: undefined,
  workflows:[],
  loadingWorkflows:false
};

const studioReducer = produce((draft, action) => {
  switch (action.type) {
    case studioActions.SET_SELECTED_NODE:
      draft.selectedNode = action.payload;
      return;
    case studioActions.SET_WORKFLOWS:
        draft.workflows = action.payload;
        draft.loadingWorkflows = false;
        return;  
   case studioActions.GET_WORKFLOWS_BEGIN:
          draft.loadingWorkflows = true;
          return;    
    default:
      return;
  }
}, initialState);

// Exports:
export default studioReducer;
