// Packages:
import React, { memo, useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import dateFormat from 'dateformat';
import {
  Drawer,
  DialogTitle,
  IconButton,
  Tooltip,
  Dialog,
  OutlinedInput,
  InputAdornment,
  FormControl,
  styled,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

// Constants:
import { WORKFLOW_IS_DRAFT, WORKFLOW_IS_PUBLISHED, WORKFLOW_IS_UNDER_REVIEW } from '../../../constants/studioV2';
import { determineWorkflowStage } from '../../../pages/StudioV2/utils';

// Styles:
import {
  Wrapper,
  Title,
  Workflow,
  Name,
  LastModified,
  CreateNewWorkflow,
  SearchRefreshWrapper
} from './styles';
import StateMachines from './StateMachines';

const StyledDrawer = styled(Drawer)`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  & .MuiPaper-root {
    top: 72px;
    height: 89.5%;
    border: 0;
    box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }
`;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem;
    max-width: 75%;
    width: 100%;
    max-height: 50%;
    height: 100%;
  }

  & .MuiTypography-root {
    font-family: 'Inter', sans-serif;
  }
`;

const StyledFormControl = styled(FormControl)`
  font-size: 14px;
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
`;

// Functions:
const parseTime = (ms) => {
  const date = new Date(parseInt(ms));
  return dateFormat(date, "h:MM TT - mmm dS, 'yy");
};

const StageIcon = ({ stage }) => {
  switch (stage) {
    case WORKFLOW_IS_DRAFT:
      return (
        <Tooltip title="Draft">
          <DraftsOutlinedIcon color="primary" fontSize={'12px'} />
        </Tooltip>
      );
    case WORKFLOW_IS_UNDER_REVIEW:
      return (
        <Tooltip title="Up for review">
          <AccessTimeOutlinedIcon color="warning" fontSize={'12px'} />
        </Tooltip>
      );
    case WORKFLOW_IS_PUBLISHED:
      return (
        <Tooltip title="Published">
          <CheckCircleOutlineOutlinedIcon color="success" fontSize={'12px'} />
        </Tooltip>
      );
    default:
      return <span />;
  }
};

const Workflows = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [filtername, setFilterName] = useState('');
  const [toggleWorkflow,setToggleWorkflow]=useState(false)
  const [isLoading,setIsLoading]=useState(false)
  let newFilteredItems;
  if (filtername?.toLowerCase() === '') {
     newFilteredItems = props.workflows;
   } else {
       newFilteredItems = props.workflows?.filter((item) =>
        item.meta.workflowName?.toLowerCase().includes(filtername?.toLowerCase())
    );
  }

  
  const handleDeleteRefresh = useCallback(() => {
    props.setBit((prev) => !prev);
  },[]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  },[]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  },[]);

  const handleFilterName= useCallback((e)=>{
    setFilterName(e.target.value);
  },[])

  return (
    <Wrapper>
      <StyledDialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle sx={{ mb: '1rem', fontSize: '1.25rem', color: '#333333' }}>
          Supervisor Comments
        </DialogTitle>
        <Tooltip title="Close">
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </StyledDialog>

      <StyledDrawer
        variant="persistent"
        anchor="left"
        open={props.openCollapse}
      >
        <Title onClick={()=>setToggleWorkflow(prev=>!prev)} style={{cursor:'pointer'}}>
         {`Switch to ${toggleWorkflow? "Workflows":"State machines"}`}
          <Tooltip title="Close">
            <IconButton
              onClick={props.handleDrawerClose}
              sx={{ position: 'absolute', right: '0.5rem' }}
            >
              <MenuOpenOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Title>

    {!toggleWorkflow?<>
        <div style={{ padding: '0 8px' }}>
          <StyledFormControl variant="outlined">
            <OutlinedInput
              id="search-workflow"
              type="text"
              onChange={handleFilterName}
              endAdornment={
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
              size="small"
              placeholder="Search workflows"
            />
          </StyledFormControl>
        </div>

        <SearchRefreshWrapper>
          <CreateNewWorkflow
            isActive={!props.isCreatingNewWorkflow}
            onClick={
              props.isCreatingNewWorkflow ? () => {} : props.createNewWorkflow
            }
          >
            <AddOutlinedIcon fontSize="small" />
            <span style={{ marginLeft: '0.5rem' }}>New</span>
          </CreateNewWorkflow>
          <CreateNewWorkflow onClick={handleDeleteRefresh}>
            <RefreshOutlinedIcon fontSize="small" />
            <span style={{ marginLeft: '0.5rem' }}>Refresh</span>
          </CreateNewWorkflow>
        </SearchRefreshWrapper>

        <div style={{ overflow: 'auto' }}>
          {props.isFetching ? (
            <Workflow isFocused={true}>
              <Name>
                <Skeleton count={0.75} />
              </Name>
              <LastModified>
                <Skeleton count={0.5} />
              </LastModified>
            </Workflow>
          ) : (
            newFilteredItems?.map((workflow) => (
              <Workflow
                isFocused={
                  workflow.workflowId === props.currentWorkflowID &&
                  determineWorkflowStage(workflow) === props.workflowStage
                }
                onClick={() => {
                  const workflowStage = determineWorkflowStage(workflow);
                  props.onClick(workflow.workflowId, workflowStage);
                }}
                key={workflow.workflowId + workflow.meta.status}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Name style={{ color: '#2f80ed' }}>
                    {workflow.meta.workflowName }
                  </Name>
                  <Tooltip title="Comment">
                    <IconButton onClick={handleOpenModal}>
                      <NotesOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>

                <Name style={{ fontSize: '12px' }}>
                  {'\n'}by: { workflow.meta.owner }
                </Name>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginTop: '5px',
                    paddingRight: '10px'
                  }}
                >
                  <LastModified>{parseTime(workflow.meta.lastEdited)}</LastModified>
                  <StageIcon stage={determineWorkflowStage(workflow)} />
                </div>
              </Workflow>
            ))
          )}
        </div>
        </>:
        <StateMachines isLoading={isLoading} setIsLoading={setIsLoading}/>
        }

      </StyledDrawer>
    </Wrapper>
  );
};

// Exports:
export default memo(Workflows);
