// Packages:
import React, { memo } from "react";
import { useSelector } from "react-redux";

// Constants:
import { NODE_TYPE } from "../../../constants/studioV2";

// Components:
import APIConfiguration from "./APIConfiguration";
import MessageConfiguration from "./MessageConfiguration";
import FormConfiguration from "./FormConfiguration";
import EmailConfiguration from "./EmailConfiguration";
import TicketConfiguration from "./TicketConfiguration";
import DecisionConfiguration from "./DecisionConfiguration";
import ShopifyConfiguration from "./ShopifyConfiguration";
import GoogleSheetsConfiguration from "./GoogleSheetsConfiguration";
import ShippingConfiguration from "./ShippingConfiguration";
import StartConfiguration from "./StartConfiguration";
import EndConfiguration from "./EndConfiguration";
import ChoiceConfiguration from "./ChoiceConfiguration";
import AIConfiguration from "./AIConfiguration";
import ParallelConfiguration from "./ParallelConfiguration";
import ParallelHandlerConfiguration from "./ParallelHandlerConfiguration";
// Styles:
import { Wrapper, Title } from "./styles";
import LogisticsConfiguration from "./LogisticsConfiguration";
import TicketureConfiguration from "./TicketureConfiguration";
import AcuityConfiguration from "./AcuityConfiguration";

const configurationComponents = {
  [NODE_TYPE.start]: StartConfiguration,
  [NODE_TYPE.api]: APIConfiguration,
  [NODE_TYPE.message]: MessageConfiguration,
  [NODE_TYPE.form]: FormConfiguration,
  [NODE_TYPE.email]: EmailConfiguration,
  [NODE_TYPE.ticket]: TicketConfiguration,
  [NODE_TYPE.shopify]: ShopifyConfiguration,
  [NODE_TYPE.decision]: DecisionConfiguration,
  [NODE_TYPE.google_sheets]: GoogleSheetsConfiguration,
  [NODE_TYPE.shipping]: ShippingConfiguration,
  // [NODE_TYPE.end]:EndConfiguration,
  [NODE_TYPE.leaf]: EndConfiguration,
  [NODE_TYPE.logistics]:LogisticsConfiguration,
  [NODE_TYPE.choice]:ChoiceConfiguration,
  [NODE_TYPE.ai]:AIConfiguration,
  [NODE_TYPE.parallel]:ParallelConfiguration,
  [NODE_TYPE.parallelHandler]:ParallelHandlerConfiguration,
  [NODE_TYPE.ticketure]:TicketureConfiguration,
  [NODE_TYPE.acuity]:AcuityConfiguration
};

// Functions:
const Configuration = ({
  updateNode,
  deleteNode,
  settings,
  currentRemoteWorkflow,
  setCurrentRemoteWorkflow,
  isEditable,
  setNodes
}) => {
  // State:
  const studio = useSelector((state) => state.studio);

  const renderConfiguration = (node) => {
    const Component = configurationComponents[node?.type];
    return Component ? (
      <Component
        node={node}
        updateNode={updateNode}
        deleteNode={deleteNode}
        settings={settings}
        currentRemoteWorkflow={currentRemoteWorkflow}
        setCurrentRemoteWorkflow={setCurrentRemoteWorkflow}
        isEditable={isEditable}
        setNodes={setNodes}
      />
    ) : (
      <span style={{ padding: "0.5rem" }}>Select a node to get started</span>
    );
  };  
  return (
    <Wrapper>
      <Title style={{ textAlign: "left", padding: "0.5rem" }}>
        Configuration
      </Title>
      <div>{renderConfiguration(studio.selectedNode)}</div>
    </Wrapper>
  );
};

// Exports:
export default memo(Configuration);
