// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  width: 20%;
  height: 98%;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  font-size: 14px;
  overflow: auto;
`

export const Title = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
`

export const InputField = styled.div`
  margin-bottom: 2rem;
`

export const Tag = styled.div`
  font-weight: 600;
  margin-bottom: 0.75rem;
`

export const Value = styled.div`
  margin-top: 0.75rem;
  color: #000000;
`

export const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.25rem;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  background-color: #E8F0FE;
`

export const Input = styled.input`
  font-size: 1rem;
  width: calc(100% - 1rem);
  font-family: 'Inter', sans-serif;
  border: none;
  outline: none;
  background-color: transparent;
`
export const FieldName = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 600;
`

export const SubFieldName = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 12px;
  color: #646464;
`

export const VariableField = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const Variable = styled.span`
  display:flex;
  align-items:center;
  width: fit-content;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 3px -3px rgba(0, 0, 0, 0.25);
  background-color: #f4f4f9;
  border-radius: 4px;
  user-select: none;
`
export const StyledDiv = styled.div`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
`;

export const RuleContainer = styled.div`
  background-color: #e5e5e5;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  overflow: scroll;
`;
