import uuid from "react-uuid";
import { CREATE_DRAFT_OPERATION, DELETE_DRAFT_OPERATION, FIRST_DRAFT_VERSION, SAVE_DRAFT_OPERATION, SUBMIT_DRAFT_OPERATION, WORKFLOW_IS_DRAFT } from "../../constants/studioV2";
import { workflowOperations } from "../../pages/StudioV2/utils/api";
import { toast } from "react-toastify";

export function createEmptyDraft(orgId, owner) {
    return {
      workflowId:uuid(),
      orgId,
      meta: {
        workflowName:'new-empty-draft',
        nodes:[],
        edges:[],
        viewport:{
            x:0,
            y:0,
            zoom:1,
        },
        settings:{},
        status: WORKFLOW_IS_DRAFT,
        isArchived: false,
        owner,
        target: 'agent',
        access: 'private',
        tags: [],
        trainingPhrases: [],
        s3Link: '',
        lastEdited:Date.now(),
        publishedBy:"",
        archivedBy:"",
        validFrom:"",
        validTo:"",
      },
      
    }
  }

  export async function createDraft(workflow) {
    const response = await workflowOperations(CREATE_DRAFT_OPERATION, workflow);
    return response?.data;
  }
  
  export async function saveDraft(workflow) {
    const response = await workflowOperations(SAVE_DRAFT_OPERATION, workflow);
    toast.info("Saving Workflow! Please wait for a few seconds while we save the workflow before submitting.")
    return response?.data;
  }
  
  export async function deleteDraft(workflow) {
    await workflowOperations(DELETE_DRAFT_OPERATION, workflow);
  }
  
  export async function submitDraft(workflow) {
    const response = await workflowOperations(SUBMIT_DRAFT_OPERATION, workflow);
    return response?.data;
  }