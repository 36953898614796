import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useReactFlow } from "react-flow-renderer";
import { getDynamicFields, getOutputFields } from "../../Components/DynamicFields";
import { Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { findNodeWithId } from "../../../../pages/StudioV2/utils";

function filterUniqueValues(data) {
  const seenIds = new Set();
  const filteredData = [];

  for (const item of data) {
      const itemId = item.id;
      if (!seenIds.has(itemId) && item.values.length > 0) {
          seenIds.add(itemId);
          filteredData.push(item);
      }
  }

  return filteredData;
}

const BaseConfiguration = ({
  children,
  setDynamicFields=()=>{},
  node,
  isEditable,
  deleteNode,
  outputFields=[],
  setOutputFields=()=>{},
  setNodes=()=>{}
}) => {
  const { getNode, getEdges, getNodes } = useReactFlow();
  const deleteIcon = useMemo(() => <DeleteOutlineOutlinedIcon />, []);

  const buttonSx = useMemo(
    () => ({
      textTransform: "none",
      mt: "1rem",
    }),
    []
  );

  useEffect(() => {
    const edges = getEdges();
    const targetEdges = edges?.filter(({ target }) => target === node?.id);

    if (targetEdges?.length !== 1) {
      let fields = [];
      let outputField = [];
      targetEdges.forEach((targetEdge) => {
        const previousNode = getNode(targetEdge.source);
        const fieldsFromPreviousNode = getDynamicFields(previousNode,targetEdge.label);
        const outputFieldFromPreviousNode = getOutputFields(previousNode,targetEdge.label);
        fields = [ ...fields, ...fieldsFromPreviousNode ];
        outputField = [ ...outputField, ...outputFieldFromPreviousNode ];
      });
      setDynamicFields([...new Set([...fields])]);
      setOutputFields([...filterUniqueValues([...outputFields,...outputField])]);
      return;
    }
    const previousNode = getNode(targetEdges[0].source);    
    const fields = getDynamicFields(previousNode,targetEdges[0].label);
    const outputField=getOutputFields(previousNode,targetEdges[0].label)
    setOutputFields([...filterUniqueValues([...outputFields,...outputField])])
    setDynamicFields([...new Set([...fields])]);
  }, [node?.id, getEdges, getNode]);

  const handleDeleteNode = useCallback(() => {
      const nodes=getNodes()
      const edges=getEdges()
      const targetEdges = edges?.filter(({ target }) => target === node?.id);
      targetEdges?.map(({source,target})=>{
      const sourceNode=  findNodeWithId(source,nodes)
      const targetNode=  findNodeWithId(target,nodes)
      if(sourceNode.type==='choice'){
        const branchName = `branch_${target}`;
        const updatedChoices = sourceNode.data.choices.filter(choice => choice.branch !== branchName);
        const updatedNodes = nodes.map(node => {
        if (node.id === source) {
          return {
            ...node,
            data: {
              ...node.data,
              choices: updatedChoices
            }
          };
        }
        return node;
      });
      setNodes(updatedNodes)
      }
      }
      )
      deleteNode(node.id);
 
  }, [deleteNode,node.id,getEdges, getNode]);

  return <>
  {children}
  <Button
        variant="contained"
        color="error"
        size="small"
        disabled={!isEditable}
        startIcon={deleteIcon}
        sx={buttonSx}
        onClick={handleDeleteNode}
      >
        Delete Node
      </Button>
  </>;
};

export default memo(BaseConfiguration);
