import { contentActions } from "../actions/contentActions";
import produce from "immer";

const initialState = {
  loading: false,
  loadingArticles: false,
  searching: false,
  error: null,
  similarity: [],
  qna: [],
  answer: [],
  workflow: null,
  unverifiedQna: [],
  unverifiedIntent: [],
  draftQna: [],
  draftIntent: [],
  openedArticle: {},
  interactData: {},
  listqna: [],
  org_info: {},
  orgCustomInfo: {},
  personas: [],
  agent_status: "off",
  agent_type: "endUser",
  agent_personas: [],
  activityRecords: null,
  query: "",
  reactionRecords: null,
  reactionRecordsV2: [],
  reactionRecordsLastKey: null,
  eventCounts: {},
  orderStatus: null,
  agents: [],
  workflow_url: null,
  feedback: [],
  loadingArticlesV2: false,
  loadingOpenArticleV2: false,
  articlesV2: [],
  articlesLastKey: null,
  openArticleV2: {},
  workflows: [],
};

const contentReducer = produce((draft, action) => {
  switch (action.type) {
    case contentActions.SET_LOADING:
      draft.loading = action.payload;
      return;
    case contentActions.UPDATE_QUERY:
      draft.query = action.payload;
      return;
    case contentActions.INTERACT_WITH_AI_BEGIN:
      draft.searching = true;
      return;
    case contentActions.INTERACT_WITH_AI_SUCCESS_TRACK: {
      draft.interactData = {
        qna: action.payload.qna,
        intent: action.payload.intent,
      };
      if (action?.payload?.workflow) {
        draft.workflow = action?.payload?.workflow;
      }
      draft.searching = false;
      return;
    }
    case contentActions.INTERACT_WITH_AI_ERROR:
      draft.searching = false;
      draft.error = action.payload;
      return;
    case contentActions.CLEAR_CONTENT_TRACK:
      draft.loading = false;
      draft.error = null;
      draft.interactData = {};
      draft.similarity = [];
      draft.qna = [];
      draft.answer = [];
      draft.articlesV2 = [];
      draft.articlesLastKey = null;
      return;
    case contentActions.LOAD_SIMILARITY_BEGIN:
      draft.searching = true;
      return;
    case contentActions.LOAD_SIMILARITY_SUCCESS:
      draft.similarity = action.payload;
      draft.searching = false;
      return;
    case contentActions.LOAD_SIMILARITY_ERROR:
      draft.searching = false;
      draft.error = action.payload;
      return;
    case contentActions.LOAD_KA_BEGIN:
      draft.loadingArticles = true;
      return;
    case contentActions.LOAD_KA_SUCCESS_TRACK:
      draft.qna = action.payload.qna;
      draft.answer = action.payload.intent;
      draft.loadingArticles = false;
      return;
    case contentActions.LOAD_KA_ERROR:
      draft.loadingArticles = false;
      draft.error = action.payload;
      return;
    case contentActions.DELETE_KA_BEGIN:
      draft.loading = true;
      return;
    case contentActions.DELETE_KA_SUCCESS_TRACK:
      draft.openedArticle = {};
      draft.loading = false;
      return;
    case contentActions.DELETE_KA_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.SET_ARTICLE_BEGIN:
      draft.loading = true;
      return;
    case contentActions.SET_ARTICLE_SUCCESS_TRACK:
      draft.openedArticle = action.payload;
      draft.loading = false;
      return;
    case contentActions.SET_ARTICLE_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.ORG_INFO_BEGIN:
      draft.loading = true;
      return;
    case contentActions.ORG_INFO_SUCCESS:
      draft.org_info = action.payload;
      draft.loading = false;
      return;
    case contentActions.ORG_CUSTOM_INFO:
      draft.orgCustomInfo = action.payload?.customizations;
      draft.personas = action.payload?.personas || [];
      draft.loading = false;
      return;
    case contentActions.ORG_INFO_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.HANDLE_USER_STATUS_SUCCESS:
      draft.agent_status = action.payload;
      draft.loading = false;
      return;
    case contentActions.OPEN_ARTICLE_TRACK:
      let temp = { ...action.payload };
      let newData;
      if (typeof temp?.meta === "string" && temp?.meta.length > 0) {
        const meta = JSON.parse(temp.meta);
        newData = {
          ...temp,
          meta: {
            ...meta,
            source: meta.url,
          },
        };
        delete newData?.meta?.url;
      } else if (typeof temp?.meta === "object" && temp?.meta?.url) {
        newData = {
          ...temp,
          meta: {
            ...temp?.meta,
            source: temp?.meta?.url,
          },
        };
        delete newData?.meta?.url;
      } else if (typeof typeof temp?.meta === "object" && temp?.meta?.source) {
        newData = {
          ...temp,
          meta: {
            ...temp?.meta,
            source: temp?.meta?.source,
          },
        };
      } else {
        newData = { ...temp };
      }
      localStorage.setItem("openedArticle", JSON.stringify(newData));
      draft.openedArticle = newData;
      return;
    case contentActions.GET_AGENT_TYPE_SUCCESS:
      draft.agent_type = action.payload?.agent_type;
      draft.agent_personas = action.payload?.personas ?? [];
      draft.loading = false;
      return;
    case contentActions.FETCH_ACTIVITY_RECORDS_BEGIN:
      draft.loading = true;
      return;
    case contentActions.FETCH_ACTIVITY_RECORDS_SUCCESS_TRACK:
      draft.loading = false;
      draft.activityRecords = action.payload;
      return;
    case contentActions.FETCH_ACTIVITY_RECORDS_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.ADD_REACTION_BEGIN:
      draft.loading = true;
      return;
    case contentActions.ADD_REACTION_SUCCESS_TRACK:
      draft.loading = false;
      return;
    case contentActions.ADD_REACTION_ERROR:
      draft.loading = false;
      return;
    case contentActions.FETCH_REACTION_BEGIN:
      draft.loading = true;
      return;
    case contentActions.FETCH_REACTION_BEGIN_V2:
      draft.loading = true;
      return;
    case contentActions.FETCH_REACTION_SUCCESS_TRACK:
      draft.loading = false;
      draft.reactionRecords = action.payload;
      return;
    case contentActions.FETCH_REACTION_SUCCESS_TRACK_V2:
      draft.loading = false;
      draft.reactionRecordsV2.push(...action.payload?.reactions);
      draft.reactionRecordsLastKey = action.payload?.lastKey;
      return;
    case contentActions.CLEAR_TABLE_REACTION_RECORDS:
      draft.reactionRecordsV2 = [];
      draft.reactionRecordsLastKey = null;
      return;
    case contentActions.FETCH_REACTION_ERROR:
      draft.loading = false;
      return;
    case contentActions.FETCH_REACTION_ERROR_V2:
      draft.loading = false;
      return;
    case contentActions.FETCH_ORDER_BEGIN:
      draft.loading = true;
      return;
    case contentActions.FETCH_ORDER_SUCCESS:
      draft.loading = false;
      draft.orderStatus = action.payload;
      return;
    case contentActions.FETCH_ORDER_ERROR:
      draft.loading = false;
      return;
    case contentActions.FETCH_EVENTS_COUNT_BEGIN:
      draft.loading = true;
    case contentActions.FETCH_EVENTS_COUNT_SUCCESS:
      draft.eventCounts = {
        ...draft.eventCounts,
        ...action.payload
      }
      draft.loading = false;
      return;
    case contentActions.FETCH_ACTIVITY_RECORDS_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.GET_AGENTS_BEGIN:
      draft.loading = true;
      return;
    case contentActions.GET_AGENTS_SUCCESS:
      draft.agents = action.payload.body;
      draft.loading = false;
      return;
    case contentActions.GET_AGENTS_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.HANDLE_AGENTS_BEGIN:
      draft.loading = true;
      return;
    case contentActions.HANDLE_AGENTS_SUCCESS:
      draft.loading = false;
      return;
    case contentActions.HANDLE_AGENTS_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.CREATE_ENDUSER_ACCOUNT_BEGIN:
      draft.loading = true;
      return;
    case contentActions.CREATE_ENDUSER_ACCOUNT_SUCCESS:
      draft.loading = false;
      return;
    case contentActions.CREATE_ENDUSER_ACCOUNT_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.LOAD_UNVERIFIED_KA_BEGIN:
      return;
    case contentActions.LOAD_UNVERIFIED_KA_SUCCESS_TRACK:
      draft.unverifiedQna = action.payload.qna;
      draft.unverifiedIntent = action.payload.intent;
      return;
    case contentActions.LOAD_UNVERIFIED_KA_ERROR:
      draft.error = action.payload;
      return;
    case contentActions.DELETE_UNVERIFIED_KA_BEGIN:
      draft.loading = true;
      return;
    case contentActions.DELETE_UNVERIFIED_KA_SUCCESS_TRACK:
      draft.openedArticle = {};
      draft.loading = false;
      return;
    case contentActions.DELETE_UNVERIFIED_KA_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.SET_UNVERIFIED_KA_BEGIN:
      draft.loading = true;
      return;
    case contentActions.SET_UNVERIFIED_KA_SUCCESS_TRACK:
      draft.openedArticle = action.payload;
      draft.loading = false;
      return;
    case contentActions.SET_UNVERIFIED_KA_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.LOAD_DRAFTS_BEGIN:
      return;
    case contentActions.LOAD_DRAFTS_SUCCESS_TRACK:
      draft.draftQna = action.payload.qna;
      draft.draftIntent = action.payload.intent;
      return;
    case contentActions.LOAD_DRAFTS_ERROR:
      draft.error = action.payload;
      return;
    case contentActions.DELETE_DRAFT_BEGIN:
      draft.loading = true;
      return;
    case contentActions.DELETE_DRAFT_SUCCESS_TRACK:
      draft.openedArticle = {};
      draft.loading = false;
      return;
    case contentActions.DELETE_DRAFT_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.AUTOSAVE_BEGIN:
      draft.loading = true;
      return;
    case contentActions.AUTOSAVE_SUCCESS:
      draft.openedArticle = action.payload;
      draft.loading = false;
      return;
    case contentActions.AUTOSAVE_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.RENDER_WORKFLOW_URL_START:
      draft.loading = true;
      return;
    case contentActions.RENDER_WORKFLOW_URL_SUCCESS:
      draft.loading = false;
      draft.workflow_url = action.payload;
      return;
    case contentActions.RENDER_WORKFLOW_URL_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.ADD_FEEDBACK_BEGIN:
      draft.loading = true;
      return;
    case contentActions.ADD_FEEDBACK_SUCCESS:
      draft.loading = false;
      return;
    case contentActions.ADD_FEEDBACK_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.FETCH_FEEDBACK_BEGIN:
      draft.loading = true;
      return;
    case contentActions.FETCH_FEEDBACK_SUCCESS:
      draft.feedback = action.payload;
      draft.loading = false;
      return;
    case contentActions.FETCH_FEEDBACK_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case contentActions.SET_ORDER_STATUS:
      draft.orderStatus = action.payload;
      return;
    case contentActions.LOAD_ARTICLES_BEGIN:
      draft.loadingArticlesV2 = true;
      return;
    case contentActions.LOAD_ARTICLES_SUCCESS:
      draft.loadingArticlesV2 = false;
      const { articles, reset, lastKey } = action.payload;

      if (lastKey && draft.articlesLastKey === lastKey) {
        return; // safeguard for mutiple requests with same start key
      }

      draft.articlesLastKey = lastKey;
      draft.articlesV2 = [
        ...(reset ? [] : draft.articlesV2),
        ...(articles ?? [])
      ];
      return;
    case contentActions.LOAD_ARTICLES_ERROR:
      draft.loadingArticlesV2 = false;
      draft.articlesLastKey = null;
      draft.error = action.payload;
      return;
    case contentActions.SET_WORKFLOWS:
      draft.workflows = action.workflows;
      return;
    default:
      return;
  }
}, initialState);

export default contentReducer;
