import { CallReceived } from '@mui/icons-material';
import produce from 'immer';
import uuid from 'react-uuid';
import { urlify } from '../../lib/urlify';
import { ChatActions } from '../actions/chatActions'; 
import { formatRecords } from '../utils/chat';

const initialState = {
  chats: [
    {
      id: '0',
      type: 'bot',
      subtype: 'received',
      message: 'Hello! I’m an AI Agent. This is a recorded chat. How can I help you?'
    }
  ],
  chatOn: false,
  agentChatWorkflowStarted: false,
  workflowStarted: false,
  isBotTyping: false,
  chatRecords: {},
  chatRecordByRecordIds: {},
  workflow: false,
  incomingMessage: {},
  renderDynamicForm: false,
  baseLineChatRecords: {},
  chatRecordsLastKey: null,
  chatData: null,
  loading: false,
  error: null,
};

const chatReducer = produce((draft, action) => {
  switch (action.type) {
    case ChatActions.START_STOP_WORKFLOW:
      draft.workflowStarted = action.payload;
      return;
    case ChatActions.ADD_CONVERSATION:
      const message = urlify(
        action?.payload?.message
          ?.replace(/&quot;/g, '"')
          ?.replace(/&lt;/g, '<')
          ?.replace(/&gt;/g, '>')
      );
      draft.chats.push({ ...action.payload, message });
      return;
    case ChatActions.ADD_FEEDBACK_DATA:
      draft.chatData = action.payload;
      return;
    case ChatActions.CLEAR_CHAT_TRACK:
      draft.chats = action.payload;
      draft.chatOn = false;
      draft.agentChatWorkflowStarted = false;
      return;
    case ChatActions.CHAT_STARTED_TRACK:
      draft.chatOn = true;
      return;
    case ChatActions.CHAT_ENDED_TRACK:
      draft.chatOn = false;
      return;
    case ChatActions.START_SLACK_CONVERSATION_TRACK:
      draft.agentChatWorkflowStarted = true;
      return;
    case ChatActions.STOP_SLACK_CONVERSATION_TRACK:
      draft.agentChatWorkflowStarted = false;
      return;
    case ChatActions.START_WORKFLOW_EXECUTION:
      draft.workflow = true;
      return;
    case ChatActions.STOP_WORKFLOW_EXECUTION:
      draft.workflow = false;
      return;
    case ChatActions.START_AGENT_CHAT_WORKFLOW:
      draft.agentChatWorkflowStarted = true;
      return;
    case ChatActions.STOP_AGENT_CHAT_WORKFLOW:
      draft.agentChatWorkflowStarted = false;
      return;
    case ChatActions.LOAD_QNA_BEGIN:
      draft.loading = true;
      return;
    case ChatActions.LOAD_QNA_SUCCESS:
      draft.loading = false;
      return;
    case ChatActions.LOAD_QNA_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case ChatActions.BOT_TYPING:
      draft.isBotTyping = action.payload;
      return;
    case ChatActions.FETCH_CHAT_RECORDS_BEGIN:
      draft.loading = true;
      draft.error = null;
      return;
    case ChatActions.FETCH_CHAT_RECORDS_SUCCESS_TRACK:
      draft.loading = false;
      draft.chatRecords = {
        ...draft.chatRecords,
        ...formatRecords(action.payload?.chatRecords)
      }
      draft.chatRecordsLastKey = action.payload.lastKey;
      return;
    case ChatActions.FETCH_BASELINE_CHAT_RECORDS_SUCCESS_TRACK:
      draft.loading = false;
      draft.baseLineChatRecords = {
        ...draft.baseLineChatRecords,
        ...formatRecords(action.payload?.chatRecords)
      }
      draft.chatRecordsLastKey = action.payload.lastKey;
      return;
    case ChatActions.FETCH_CHAT_RECORD_BY_RECORD_ID_SUCCESS:
      if(!(action.payload?.recordId in draft.chatRecordByRecordIds)){
        draft.chatRecordByRecordIds[action.payload?.recordId] = action.payload;
      }
      draft.loading = false;
      return;
    case ChatActions.UPDATE_TABLE_CHAT_RECORD:
      draft.chatRecords[action.payload.record_id].tags = action.payload.tags;
      draft.chatRecords[action.payload.record_id].notes = action.payload.notes;
      return;
    case ChatActions.CLEAR_TABLE_CHAT_RECORDS:
      draft.chatRecords = {}
      draft.baseLineChatRecords = {}
      draft.chatRecordsLastKey = null
      return;
    case ChatActions.RENDER_DYNAMIC_FORM:
      draft.loading = false;
      draft.renderDynamicForm = action.payload;
      return;
    case ChatActions.FETCH_CHAT_RECORDS_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case ChatActions.EDIT_CHAT_RECORDS_BEGIN:
      return;
    case ChatActions.EDIT_CHAT_RECORDS_SUCCESS_TRACK:
      return;
    case ChatActions.EDIT_CHAT_RECORDS_ERROR:
      // reset data to previous chatdata
      draft.chatRecords[action.payload.data.record_id] = action.payload.data.chatData;
      draft.error = action.payload.error;
      return;
    case ChatActions.CLEAR_CHAT_ERROR_MSG:
      draft.error = "";
      return;
    case ChatActions.INCOMING_MESSAGE:
      draft.incomingMessage = action.payload;
      if (action.payload?.systemMessage?.includes('joined the chat')) {
        draft.agentChatWorkflowStarted = true;
      }
      if (
        Object.keys(action.payload).includes('chatMessage') &&
        typeof action.payload.chatMessage === 'string'
      ) {
        if (action.payload?.chatMessage?.length > 0) {
          draft.chats.push({
            type: action.payload?.type,
            subtype: 'received',
            message: action.payload?.chatMessage,
            id: uuid()
          });
        } else if (action.payload?.chatMessage?.length === 0) {
          draft.chats.push({
            type: action.payload?.type,
            subtype: 'received',
            message: 'Your request is being processed',
            id: uuid()
          });
        }
      }
      if (
        action?.payload?.messageData &&
          Object.keys(action?.payload?.messageData).includes(
            'replies_expected'
        ) &&
        typeof action?.payload?.messageData?.replies_expected === 'string'
      ) {
        const replies_expected = JSON.parse(
          action.payload?.messageData?.replies_expected
        );
        if (replies_expected.length === 1) {
          draft.chats.push({
            type: action.payload?.type,
            subtype: 'received',
            formFields:replies_expected,
            message: replies_expected?.[0]?.message,
            id: uuid()
          });
        } else if (replies_expected.length > 1) {
          draft.chats.push({
            type: 'bot',
            subtype: 'received',
            formFields: replies_expected,
            formName: action.payload?.messageData?.formName,
            id: uuid()
          });
        } else if (replies_expected.length === 0) {
          draft.agentChatWorkflowStarted = false;
          draft.workflow = false;
          draft.workflowStarted = false;
        }
      }
      if (Object.keys(action.payload).includes('systemMessage')) {
        draft.chats.push({
          systemMessage: action.payload?.systemMessage,
          id: uuid()
        });
      }
      return;
    default:
      return;
  }
}, initialState);

export default chatReducer;
