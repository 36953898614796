import React, { memo } from "react";
import dateFormat from "dateformat";
import { IdContainer, LastModified, ListContainer, MetaContainer } from "./styles";

const parseTime = (ms) => {
    const date = new Date(parseInt(ms));
    return dateFormat(date, "h:MM TT - mmm dS, 'yy");
  };

const ExecutionListItem = ({ execution, handleExecutionClicked }) => {

    const getStatusColor = () => {
        switch (execution.status) {
            case 'SUCCEEDED':
                return 'green';
            case 'FAILED':
                return 'red';
            case 'ABORTED':
                return 'orange';
            default:
                return 'inherit';
        }
    };

    return (
        <ListContainer>
            <IdContainer onClick={(e) => handleExecutionClicked(e, execution.executionArn)}>
                {execution.executionId}
            </IdContainer>
            <MetaContainer>
                {['Status', 'Start Date', 'Stop Date'].map((label) => (
                    <div key={label} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ fontWeight: '500', marginRight: '0.5rem' }}>{label}:</span>
                        <LastModified style={{color:label === 'Status' ? getStatusColor() : 'inherit'}}>
                            {label === 'Status' ? execution.status : label === 'Start Date' ? parseTime(execution.startDate) : parseTime(execution.stopDate)}
                        </LastModified>
                    </div>
                ))}
            </MetaContainer>
        </ListContainer>
    );
};

export default memo(ExecutionListItem)

