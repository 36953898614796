import { toast } from "react-toastify";
import {
  PUBLISH_DRAFT_OPERATION,
  REJECT_DRAFT_OPERATION,
} from "../../constants/studioV2";
import {
  loadSimilarData,
  workflowOperations,
} from "../../pages/StudioV2/utils/api";

export async function rejectDraft(workflow) {
 const newWorkflow= await workflowOperations(REJECT_DRAFT_OPERATION, workflow);
 return newWorkflow.data
}

export async function publishDraft(workflow) {
  const response = await workflowOperations(PUBLISH_DRAFT_OPERATION, workflow);
  return response.data
}
