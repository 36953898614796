export const APIMETHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const AUTHORIZATIONTYPE = {
  "Basic Auth": "Basic Auth",
  "API Key": "API key",
  "Bearer Token": "Bearer Token",
};
export const DEFAULT_OUTPUT_BEARER_TOKEN = {
  key: "bearerToken",
  value: {
    fieldName: null,
    value: null,
  },
};
export const DEFAULT_OUTPUT_USERNAME = {
  key: "username",
  value: {
    fieldName: null,
    value: null,
  },
};
export const DEFAULT_OUTPUT_PASSWORD = {
  key: "password",
  value: {
    fieldName: null,
    value: null,
  },
};
export const DEFAULT_OUTPUT_AUTHKEY = {
  key: "authKey",
  value: {
    fieldName: null,
    value: null,
  },
};
export const DEFAULT_OUTPUT_AUTHVALUE = {
  key: "authValue",
  value: {
    fieldName: null,
    value: null,
  },
};

export const DEFAULT_OUTPUT_QUERY_PARAMS = {
  key: null,
  value: {
    fieldName: null,
    value: null,
  },
};
