// Packages:
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

//Styles
import { Body, Description, Field, NodeLabel, NodeWrapper, StyledHandle } from '../styles';

import BaseNode from '../BaseNode';
import { config } from './config';
import useNodeStyles from '../../../../hooks/useNodeStyles';

// Functions:
const StartNode = ({ data }) => {
  // Constants:
  const { id, comment, label } = data;

  // State:
  const studio = useSelector((state) => state.studio);

  const isSelected = useSelector(
    (state) => state.studio.selectedNode?.id === id
  );

  const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})

  // Return:
  return (
    <BaseNode data={data} config={config}>
    <NodeWrapper
      style={computedWrapperStyle}
      backgroundColor={'#FEFFFE'}
      borderColor={'#008060'}
    >
       {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
      <NodeLabel
        htmlFor="text"
        style={computedLabelStyle}
      >
        {label}
      </NodeLabel>
      <Body
        style={computedBodyStyle}
      >
        <Description>
          <Field isActive={studio.selectedNode?.id === id} activeColor={'#205B23'} inActiveColor={'#3AA640'}>
            {comment.length === 0 ? (
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                Enter a reply...
              </span>
            ) : comment.length >= 30 ? (
              `${comment.slice(0, 20).trim()}..`
            ) : (
              comment
            )}
          </Field>
        </Description>
      </Body>
    </NodeWrapper>
    </BaseNode>
  );
};

// Exports:
export default memo(StartNode);
