import React, { memo } from "react";
import dateFormat from "dateformat";
import { Workflow, Name, DateContainer, LastModified } from "./styles";
import ExecutionList from "./ExecutionList";

const parseTime = (ms) => dateFormat(new Date(parseInt(ms)), "h:MM TT - mmm dS, 'yy");


const StateMachineList = ({ filteredSM, selectedSM, handleSMClick }) => {
  return filteredSM.map((sm) => (
    <div key={sm.meta.stateMachineArn}>
      <Workflow
        onClick={() => handleSMClick(sm.meta.stateMachineArn)}
        isFocused={selectedSM === sm.meta.stateMachineArn}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Name style={{ color: "#2f80ed" }}>{sm.meta.workflowName}</Name>
        </div>
        <DateContainer>
          <LastModified>{parseTime(sm.meta.validFrom)}</LastModified>
        </DateContainer>
      </Workflow>
      {selectedSM === sm.meta.stateMachineArn && (
        <div style={{ backgroundColor: "#f0f0f0" }}>
          <ExecutionList stateMachineArn={sm.meta.stateMachineArn} />
        </div>
      )}
    </div>
  ));
};

export default memo(StateMachineList)
