import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'react-uuid';
import {
    CREATE_TICKET,
    CREATE_CHAT_RECORD,
    CREATE_CHAT_RECORD_V2
} from "../constants/Endpoint";
toast.configure();

export const createTicket = async (
    comment,
    ticketing_system,
    validated_email,
    recordId,
    conv_type,
    org_id,
    agent_type_info
) => {
    try {
        let response;
        if (ticketing_system === 'default') {
            const orgId = localStorage.getItem('org_id');
            let created_date =
                new Date().getUTCFullYear() +
                '-' +
                String(new Date().getUTCMonth() + 1).padStart(2, '0') +
                '-' +
                String(new Date().getUTCDate()).padStart(2, '0') +
                ' ' +
                String(new Date().getUTCHours()).padStart(2, '0') +
                ':' +
                String(new Date().getUTCMinutes()).padStart(2, '0');
            const record_id = recordId;
            const data = {
                org_id: orgId,
                record_id: record_id,
                user_email: validated_email,
                tags: '',
                record: comment,
                created_date: created_date,
                conversation_type: conv_type,
                connectionType: agent_type_info,
            };
            response = await axios.post(`${CREATE_CHAT_RECORD}`, data, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            // localStorage.removeItem('AIchats');
        }
        // localStorage.removeItem('validated_user');
        // localStorage.removeItem('chats');
        // localStorage.removeItem('leave_message');
        // localStorage.removeItem('unauthenticated_user');
        // if (conv_type === 'Leave Message') {
        //   toast.success('Message left successfully');
        // }
        return response;
    } catch (error) {
        throw error;
    }
};



export const createTicket_V2 = async (
    comment,
    ticketing_system,
    validated_email,
    recordId,
    conv_type,
    org_id,
    feedback,
    senderEmail,
    supportEmail,
    agent_type_info = ''
) => {
    try {
        let response;
        if (ticketing_system === 'default') {
            const orgId = localStorage.getItem('org_id');
            let created_date =
                new Date().getUTCFullYear() +
                '-' +
                String(new Date().getUTCMonth() + 1).padStart(2, '0') +
                '-' +
                String(new Date().getUTCDate()).padStart(2, '0') +
                ' ' +
                String(new Date().getUTCHours()).padStart(2, '0') +
                ':' +
                String(new Date().getUTCMinutes()).padStart(2, '0');
            let previous_interactionRecords_string = localStorage.getItem('InteractionsRecords');
            let interaction_records = previous_interactionRecords_string ? JSON.parse(previous_interactionRecords_string) : [];
            const record_id = recordId;
            const data = {
                orgId: orgId,
                recordId: record_id,
                userEmail: validated_email,
                tags: interaction_records,
                record: comment,
                createdDate: created_date,
                conversation_type: conv_type,
                feedback: feedback || {},
                connectionType: agent_type_info
            };
            response = await axios.post(`${CREATE_CHAT_RECORD_V2}`, data, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            localStorage.removeItem('AIchats');
            localStorage.removeItem('InteractionsRecords');
        } else {
            const data = {
                comment: comment,
                ticketing_system: ticketing_system,
                validated_email: validated_email,
                org_id: org_id,
                sender_email: senderEmail ?? "",
                support_email: supportEmail ?? ""
            };
            response = await axios.post(`${CREATE_TICKET}`, data, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
        }
        localStorage.removeItem('validated_user');
        localStorage.removeItem('chats');
        localStorage.removeItem('leave_message');
        localStorage.removeItem('unauthenticated_user');
        if (localStorage.getItem('InteractionsRecords') !== null) localStorage.removeItem('InteractionsRecords');
        if (conv_type === 'Leave Message') {
            toast.success('Message left successfully');
        }
        return response;
    } catch (error) {
        throw error;
    }
};

