import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  styled,
} from "@mui/material";
import Skeleton from "react-loading-skeleton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { LastModified, Name, Workflow } from "./styles";
import { getStateMachines } from "../../../pages/StudioV2/utils/api";
import { useSelector } from "react-redux";
import StateMachineList from "./StateMachineList";

const StyledFormControl = styled(FormControl)`
  font-size: 14px;
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
`;

const StateMachines = () => {
  const [stateMachines, setStateMachines] = useState([]);
  const [filteredSM, setFilteredSm] = useState([]);
  const [selectedSM, setSelectedSM] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const orgId = localStorage.getItem("org_id");
  const agentType = useSelector((state) => state.content.agent_type);

  const handleSearch = useCallback(
    (e) => {
      const filtered = stateMachines.filter((sm) =>
        sm.meta.workflowName
          .toLowerCase()
          .includes(e.target.value?.toLowerCase())
      );
      setFilteredSm(filtered);
    },
    [filteredSM]
  );

  const handleSMClick = useCallback((stateMachineArn) => {
    setSelectedSM((prevSelected) =>
      prevSelected === stateMachineArn ? null : stateMachineArn
    );
  }, []);

  const fetchStateMachines = async (orgId, agentType) => {
    try {
      setIsLoading(true);
      const stateMachines = await getStateMachines(orgId, agentType);
      setStateMachines(stateMachines?.data?.Workflows || []);
      setFilteredSm(stateMachines?.data?.Workflows || []);
    } catch (error) {
      console.error("Error fetching state machines:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchStateMachines(orgId, agentType);
    }
  }, []);

  return (
    <>
      <div style={{ padding: "0 8px", marginBottom: "1rem" }}>
        <StyledFormControl variant="outlined">
          <OutlinedInput
            id="search-sms"
            type="text"
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                <SearchOutlinedIcon />
              </InputAdornment>
            }
            size="small"
            placeholder="Search State Machines"
          />
        </StyledFormControl>
      </div>
      {isLoading === true ? (
        <Workflow isFocused={true}>
          <Name>
            <Skeleton count={0.75} />
          </Name>
          <LastModified>
            <Skeleton count={0.5} />
          </LastModified>
        </Workflow>
      ) : (
        <StateMachineList
          filteredSM={filteredSM}
          selectedSM={selectedSM}
          handleSMClick={handleSMClick}
        />
      )}
    </>
  );
};

export default memo(StateMachines);
