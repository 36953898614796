export const ACTION = {
  TRACK_ORDER: "TRACK ORDER",
};
export const CHANNEL = {
  USPS: "USPS",
};
export const DEFAULT_OUTPUT_USERID = {
  key: "userId",
  value: {
    fieldName: null,
    value: null,
  },
};
export const DEFAULT_OUTPUT_SOURCEID = {
  key: "sourceId",
  value: {
    fieldName: null,
    value: null,
  },
};
export const LOGISTICS_RESPONSE_FIELDS = [
  "shipments_tracking_details",
  "latest_shipment_status",
  "days_past_last_delivery_date",
  "latest_delivery_date",
  "tracking_url"
];
