// Packages:
import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useContext,
} from "react";
import parse, { domToReact, attributesToProps } from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import scrollToBottom from "../../../lib/scrollToBottom";
import IconButton from "@mui/material/IconButton";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import Tooltip from "@mui/material/Tooltip";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import Button from "@mui/material/Button";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';


import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

// Components:
import BotAvatar from "../BotAvatar";
import FeedbackForm from "../FeedbackForm";
import AgentList from "../AgentList";
import MobileChatActions from "../MobileChatActions";
import ChatForm from "../ChatForm";
import DynamicChatForm from "../DynamicChatForm";

// Redux:
import {
  creatingTicket,
  archivingChannel,
  creatingTicket_V2,
} from "../../../redux/actions/authActions.js";
import { addFeedback } from "../../../redux/actions/contentActions";

// Styles:
import {
  Wrapper,
  Header,
  Title,
  FlexButton,
  Container,
  Message,
  Reply,
  Conversations,
  LoadingDot,
  Footer,
  FeedbackMessage,
  SystemMessage,
  SourceLink,
  SourceSpan,
  SourceAnchor,
  VerificationUrlContainer,
} from "./styles";

import { getContentActions } from "../../../redux/actions/contentActions";
import { useDeepCompareEffect } from "react-use";
import {
  incomingMessage,
  startStopWorkflow,
} from "../../../redux/actions/chatActions";
import { WebSocketConnectionContext } from "../../../App";
import uuid from "react-uuid";
import { SUPPORTED_IFRAME_DOCS } from '../../../constants/regex.js';

import OpenAI from 'openai';

// const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true }) //what to do of this dangerouslyAllow
const openai = new OpenAI({ apiKey: "test", dangerouslyAllowBrowser: true }) //what to do of this dangerouslyAllow

const parseOptions = {
  replace: (node) => {
    if (node?.name === 'a') {
      let {href} = {...node.attribs};
      href = href.replace(/\.*$/, '');
      const anchorProps = attributesToProps({
        ...node.attribs,
        target: '_blank',
        rel: 'noreferrer',
        href: href
      });
      return <a {...anchorProps}>{domToReact(node.children, parseOptions)}</a>;
    }
  }
}

const ChatConversation = ({
  leaveChat,
  setTab,
  numberOfResources,
  handleClick,
  previewSource,
}) => {
  // Constants:
  const dispatch = useDispatch();

  // State:
  const [audioElements, setAudioElements] = useState([]);
  const [open, setOpen] = useState(false);
  const [recordUuid, setRecordUuid] = useState({
    id1: Math.random().toString(36).slice(2),
    id2: Math.random().toString(36).slice(2)
  });
  const [agentList, setAgentList] = useState(false);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const agent_type_info = useSelector((state) => state.content.agent_type);
  const query = useSelector((state) => state.content.query);
  const workflowId = useSelector(
    (state) => state?.content?.workflow?.workflowId
  );
  const workflowName = useSelector(
    (state) => state?.content?.workflow?.workflowName
  );
  const ticketId = useSelector(
    (state) => state?.content?.workflow?.ticketId
  );
  const chats = useSelector((state) => state.chat.chats);
  const renderDynamicForm = useSelector(
    (state) => state.chat.renderDynamicForm
  );
  const waitingMsg = "Please wait a few more moments while we try to answer your question."
  const agentChatWorkflowStarted = useSelector(
    (state) => state.chat.agentChatWorkflowStarted
  );
  const isBotTyping = useSelector((state) => state.chat.isBotTyping);
  const articleData = useSelector(
    (state) => state.content.interactData?.intent
  );
  const contentActions = getContentActions(dispatch);
  const { sendJsonMessage } = useContext(WebSocketConnectionContext);
  const [conversation, setConversation] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const verificationUrl = useSelector(
    (state) => state?.chat?.incomingMessage?.messageData?.verification_url
  );

  // Ref:
  const messagesEndRef = useRef(null);

  // Effects:

  useDeepCompareEffect(() => {
    const tempCon = chats.map((chat) => {
      return {
        ...chat,
        likeStatus: 0,
      };
    });
    setConversation(tempCon);
  }, [chats]);

  useEffect(() => {
    scrollToBottom(messagesEndRef);
  }, [conversation]);
  
  const createTicket = () => {
    if (localStorage.getItem("chats") !== null) {
      dispatch(
        creatingTicket(
          localStorage.getItem("AIchats") +
            localStorage.getItem("chats") +
            localStorage.getItem("leave_message"),
          localStorage.getItem("ticketing_system"),
          localStorage.getItem("validated_user"),
          localStorage.getItem("slack_channel_id"),
          "Agent",
          false,
          orgid,
          agent_type_info
        )
      );
    }
    if (localStorage.getItem("AIchats") !== null) {
      dispatch(
        creatingTicket(
          localStorage.getItem("AIchats") +
            localStorage.getItem("chats") +
            localStorage.getItem("leave_message"),
          "default",
          userDetails?.email || "anonymous",
          recordUuid.id1,
          "Testing",
          false,
          agent_type_info
        )
      );
    }
    if (localStorage.getItem("leave_message") !== null) {
      dispatch(
        creatingTicket(
          localStorage.getItem("AIchats") +
            localStorage.getItem("chats") +
            localStorage.getItem("leave_message"),
          localStorage.getItem("ticketing_system"),
          `${
            localStorage.getItem("unauthenticated_user") !== null
              ? localStorage.getItem("unauthenticated_user")
              : localStorage.getItem("validated_user")
          }`,
          recordUuid.id2,
          "Leave Message",
          false,
          orgid,
          agent_type_info
        )
      );
    }
    if (localStorage.getItem("validation_comments") !== null) {
      dispatch(
        addFeedback(
          orgid,
          `${
            localStorage.getItem("unauthenticated_user") !== null
              ? localStorage.getItem("unauthenticated_user")
              : localStorage.getItem("validated_user")
          }`,
          3,
          "Total Attempts:" +
            localStorage.getItem("validation_attempts") +
            "\n" +
            localStorage.getItem("validation_comments"),
          "Agent Connection",
          agent_type_info
        )
      );
    }
    if (localStorage.getItem("slack_channel_id") !== null) {
      dispatch(archivingChannel(localStorage.getItem("slack_channel_id")));
    }
  };


  const createTicket_V2 = (feedback={}) => {
    if (localStorage.getItem("chats") !== null) {
      dispatch(
        creatingTicket_V2(
          localStorage.getItem("AIchats") +
            localStorage.getItem("chats") +
            localStorage.getItem("leave_message"),
          localStorage.getItem("ticketing_system"),
          localStorage.getItem("validated_user"),
          localStorage.getItem("slack_channel_id"),
          "Agent",
          orgid,
          feedback,
          localStorage.getItem("senderEmail"),
          localStorage.getItem("supportEmail"),
          agent_type_info
        )
      );
    }
    if (localStorage.getItem("AIchats") !== null) {
      dispatch(
        creatingTicket_V2(
          localStorage.getItem("AIchats") +
            localStorage.getItem("chats") +
            localStorage.getItem("leave_message"),
          "default",
          userDetails?.email || "anonymous", //user_email
          recordUuid.id1,
          "Testing",
          orgid,
          feedback,
          localStorage.getItem("senderEmail"),
          localStorage.getItem("supportEmail"),
          agent_type_info
        )
      );
    }
    if (localStorage.getItem("leave_message") !== null) {
      dispatch(
        creatingTicket_V2(
          localStorage.getItem("AIchats") +
            localStorage.getItem("chats") +
            localStorage.getItem("leave_message"),
          localStorage.getItem("ticketing_system"),
          `${
            localStorage.getItem("unauthenticated_user") !== null
              ? localStorage.getItem("unauthenticated_user")
              : localStorage.getItem("validated_user")
          }`,
          recordUuid.id2,
          "Leave Message",
          orgid,
          feedback,
          localStorage.getItem("senderEmail"),
          localStorage.getItem("supportEmail"),
          agent_type_info
        )
      );
    }
    if (localStorage.getItem("validation_comments") !== null) {
      dispatch(
        addFeedback(
          orgid,
          `${
            localStorage.getItem("unauthenticated_user") !== null
              ? localStorage.getItem("unauthenticated_user")
              : localStorage.getItem("validated_user")
          }`,
          3,
          "Total Attempts:" +
            localStorage.getItem("validation_attempts") +
            "\n" +
            localStorage.getItem("validation_comments"),
          "Agent Connection",
          feedback,
          agent_type_info
        )
      );
    }
    if (localStorage.getItem("slack_channel_id") !== null) {
      dispatch(archivingChannel(localStorage.getItem("slack_channel_id")));
    }
  };

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    createTicket_V2({});
    setRecordUuid({
      id1: Math.random().toString(36).slice(2),
      id2: Math.random().toString(36).slice(2)
    })
    setOpen(false);
  };

  const handleSubmitClose = useCallback(() => {
    setRecordUuid({
      id1: Math.random().toString(36).slice(2),
      id2: Math.random().toString(36).slice(2)
    })
    setOpen(false);
  }, []);

  const handleAgentListClose = useCallback(() => {
    setAgentList(false);
  }, []);

  const changeLikeStatusOfConversation = (status, id) => {
    const tempResources = conversation.map((constants) => {
      if (constants.id === id) {
        return {
          ...constants,
          likeStatus: status,
        };
      } else return constants;
    });

    setConversation(tempResources);
  };

  const addReaction = async (type) => {
    try {
      const meta = Array.isArray(articleData?.meta) &&
        articleData.meta.length > 0 &&
        (articleData?.meta[0] ?? {});
      const data = {
        orgid,
        user_email: userDetails?.email || 'anonymous',
        record_id: localStorage.getItem("chats") !== null ? localStorage.getItem("slack_channel_id") : localStorage.getItem("AIchats") !== null ? recordUuid.id1 : localStorage.getItem("leave_message") !== null ? recordUuid.id2 : null,
        type,
        subtype: 'intent',
        article_id: meta.aid ?? meta.articleId ?? '',
        title: meta.title ?? '',
        query
      };
      const data_V2 = {
        orgId: orgid,
        userEmail: userDetails?.email || "anonymous",
        recordId: localStorage.getItem("chats") !== null ? localStorage.getItem("slack_channel_id") : localStorage.getItem("AIchats") !== null ? recordUuid.id1 : localStorage.getItem("leave_message") !== null ? recordUuid.id2 : null,
        eventType: type,
        subtype: "intent",
        eventId: uuid(),
        title: meta.title ?? "",
        query,
      };
      await contentActions.reactOnArticle(data);
      await contentActions.reactOnArticleV2(data_V2);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStartWorkflow = () => {
    dispatch(startStopWorkflow(true));
    const stateMachineMsg = {
      action: "stateMachine",
      orgid: orgid,
      workflowid: workflowId,
      ticketId
    };

    sendJsonMessage(stateMachineMsg);
  };

  const playAudio = async (audioData) => {
    try{
      return new Promise((resolve) => {
        const newAudioElements = [...audioElements];

        const audio = new Audio();
        const blob = new Blob([audioData], { type: 'audio/wav' });
        const objectURL = URL.createObjectURL(blob);
        
        audio.src = objectURL;
        audio.onended = () => {
          resolve();
          audio.pause();
          URL.revokeObjectURL(objectURL);
          setIsSpeaking(false);
          const index = newAudioElements.indexOf(audio);
          if (index !== -1) {
            newAudioElements.splice(index, 1);
          }
        };

        audio.play();
        newAudioElements.push(audio);
        setAudioElements(newAudioElements);
      });
    }
    catch(error){
      console.error("Error playing audio:", error);
    }
  };

  const voiceResponse = async(message) =>{
    try{
      const synth = window.speechSynthesis;
      if(!synth){
        if(!isSpeaking){
          const mp3 = await openai.audio.speech.create({
            model: "tts-1",
            voice: "alloy",
            input: message,
          });
          setIsSpeaking(true);
          const audioData = await mp3.arrayBuffer();
          await playAudio(audioData);
        }
        else{
          audioElements.forEach((audio) => {
            audio.pause();
            audio.removeAttribute('src');
            audio.currentTime = 0;
            URL.revokeObjectURL(audio.src);
          });
          setAudioElements([])
          setIsSpeaking(false);
        }
      }
      else{
        if(!isSpeaking){
          const utter = new SpeechSynthesisUtterance(message);
          synth.speak(utter);
          utter.onend = () =>{
            setIsSpeaking(false);
            synth.cancel();
          };
        }
        else{
          synth.cancel(); 
        }
        setIsSpeaking(!isSpeaking);
      }
    }
    catch(error){
      console.error("Error processing voice response:", error);
    }
  }

  const resetChat = () => {
    setRecordUuid({
      id1: Math.random().toString(36).slice(2),
      id2: Math.random().toString(36).slice(2)
    })
    sendJsonMessage({
      action: "resetChat",
      orgid
    });
  };

  const showFeedbackTooltip = (chatText) =>
    !agentChatWorkflowStarted &&
    chatText?.subtype === "received" &&
    chatText?.message !== "Hello! How can I help you?" &&
    chatText?.message !==
      "Hello! I’m an AI Agent. This is a recorded chat. How can I help you?" &&
    chatText?.message !== "Can you please elaborate?" &&
    chatText?.message !==
      "Sorry we couldn’t resolve your issue. Please click to connect with our support team.";

  // Return:
  return (
    <Wrapper>
      <Container>
        <Header>
          <Title>Customer Service</Title>
          <MobileChatActions
            createTicket={createTicket}
            leaveChat={leaveChat}
            handleClickOpen={handleClickOpen}
            setTab={setTab}
            numberOfResources={numberOfResources}
          />

          <FlexButton>
            <Tooltip title="Reset Chat">
              <IconButton
                color="warning"
                onClick={() => [createTicket(), createTicket_V2({}), resetChat(), leaveChat()]}
              >
                <RestartAltOutlinedIcon />
              </IconButton>
            </Tooltip>

            {(agent_type_info === "superadmin" ||
              agent_type_info === "admin") && (
              <Tooltip title="Ongoing Conversations">
                <IconButton color="success" onClick={() => setAgentList(true)}>
                  <SupportAgentOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Leave Chat">
              <IconButton
                color="error"
                onClick={() => [createTicket(), leaveChat(), handleClickOpen()]}
              >
                <ExitToAppOutlinedIcon />
              </IconButton>
            </Tooltip>
          </FlexButton>
        </Header>

        <FeedbackForm open={open} handleClose={handleClose} handleSubmitClose={handleSubmitClose} createTicket_V2={createTicket_V2}/>

        {(agent_type_info === "superadmin" || agent_type_info === "admin") && (
          <AgentList open={agentList} handleClose={handleAgentListClose} />
        )}

        <Scrollbars style={{ width: "100%", height: "calc(100% - 2rem)" }}>
          <Conversations>
            {conversation.length > 0 &&
              conversation?.map((chatText) =>
                chatText?.systemMessage ? (
                  <SystemMessage key={chatText.id}>
                    {chatText?.systemMessage}
                  </SystemMessage>
                ) : chatText?.formFields ? (
                  <ChatForm
                    form={chatText}
                    active={
                      chatText?.id === conversation[conversation.length - 1].id
                    }
                  />
                ) : (
                  <Message key={chatText.id} subtype={chatText?.subtype}>
                    {(chatText.type === "bot" ||
                      chatText.type === "stateMachine") && <BotAvatar />}
                    <Reply subtype={chatText?.subtype}>
                      <div>
                        {chatText?.message &&
                          typeof chatText?.message === "string" &&
                          parse(
                            chatText?.message?.replace?.(/(?:\\n)/g, "<br>"), parseOptions
                          )}
                      </div>
                      {showFeedbackTooltip(chatText) &&
                        (chatText?.sources?.length > 0) && (
                          <Footer>
                            <div style={{ marginRight: "1rem" }}>Sources:</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                rowGap: '0.5rem',
                                columnGap: "1.5rem",
                                flexWrap: "wrap",
                              }}
                            >
                              {chatText.sources.map(
                                (source, index) =>
                                  source && (
                                    SUPPORTED_IFRAME_DOCS.test(source.url)
                                      ? (
                                        <SourceSpan
                                          title={`Preview ${source.url}`}
                                          onClick={() => previewSource(source.url, source.pageNumber)}
                                        >
                                          {index + 1}. {source.title}
                                          {(Number.isNaN(source.pageNumber) || source?.pageNumber===undefined) ? '' : ` - Page ${source.pageNumber}`}
                                  </SourceSpan>
                                      ) : (
                                        <SourceAnchor
                                          title={`Go to ${source.url}`}
                                          href={source.url}
                                          target="_blank"
                                        >
                                          {index + 1}. {source.title}
                                          <OpenInNewOutlinedIcon
                                            sx={{ fontSize: 14 }}
                                          />
                                        </SourceAnchor>
                                      )
                                  )
                              )}
                            </div>
                          </Footer>
                        )}
                      {showFeedbackTooltip(chatText) && (chatText?.message!==waitingMsg) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginTop: "0.5rem",
                            justifyContent:'space-between'
                          }}
                        >
                          {(chatText?.type === "stateMachine" && verificationUrl)&&(
                        <VerificationUrlContainer>
                        <Tooltip
                         title={verificationUrl}
                         arrow
                        >
                          <a target="_blank" href={verificationUrl} style={{fontWeight:500}} >
                             verification url
                          </a>
                        </Tooltip>
                        </VerificationUrlContainer>)}

                        {isSpeaking ? (
                            <Tooltip title="stop-reading" style={{marginRight: '0.5rem'}} placement='top'>
                              <IconButton
                                onClick={() => voiceResponse(chatText?.message)}
                                edge="end"
                              >
                                <VolumeUpIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="read-aloud" style={{marginRight: '0.5rem'}} placement='top'>
                              <IconButton
                                onClick={() => voiceResponse(chatText?.message)}
                                edge="end"
                              >
                                <VolumeOffIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginRight: "0.5rem" }}>
                              Was this helpful?
                            </span>
                            <div>
                              {chatText?.likeStatus === 1 ? (
                                <Tooltip title="Undo" placement="top">
                                  <IconButton
                                    sx={{ color: "#109648" }}
                                    onClick={() =>
                                      changeLikeStatusOfConversation(
                                        0,
                                        chatText.id
                                      )
                                    }
                                  >
                                    <ThumbUpIcon sx={{ fontSize: 16 }} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Like" placement="top">
                                  <IconButton
                                    onClick={() => {
                                      addReaction("like");
                                      changeLikeStatusOfConversation(
                                        1,
                                        chatText.id
                                      );
                                    }}
                                  >
                                    <ThumbUpOutlinedIcon
                                      sx={{ fontSize: 16 }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {chatText?.likeStatus === -1 ? (
                                <Tooltip title="Undo" placement="top">
                                  <IconButton
                                    sx={{ color: "#ED474A" }}
                                    onClick={() =>
                                      changeLikeStatusOfConversation(
                                        0,
                                        chatText.id
                                      )
                                    }
                                  >
                                    <ThumbDownIcon sx={{ fontSize: 16 }} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Dislike" placement="top">
                                  <IconButton
                                    onClick={() => {
                                      addReaction("dislike");
                                      changeLikeStatusOfConversation(
                                        -1,
                                        chatText.id
                                      );
                                    }}
                                  >
                                    <ThumbDownOutlinedIcon
                                      sx={{ fontSize: 16 }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                            <div style={{ marginLeft: "1rem" }}>
                              <FeedbackMessage
                                style={{
                                  color:
                                    chatText?.likeStatus === 1
                                      ? "#109648"
                                      : "#ED474A",
                                }}
                              >
                                {chatText?.likeStatus === 1
                                  ? "Glad it helped!"
                                  : chatText?.likeStatus === -1
                                  ? "Sorry we couldn't help you."
                                  : ""}
                              </FeedbackMessage>
                            </div>
                          </div>
                        </div>
                      )}
                    </Reply>
                    {chatText.type === "bot" &&
                      [
                        "Sorry we couldn’t resolve your issue. Please click to connect with our support team.",
                        "We noticed that you might need some assistance.",
                      ].includes(chatText?.message) && (
                        <Button
                          variant="contained"
                          size="medium"
                          startIcon={<SupportAgentOutlinedIcon />}
                          onClick={handleClick}
                          sx={{ textTransform: "none", marginLeft: "0.5rem" }}
                        >
                          Connect with an agent
                        </Button>
                      )}
                    {chatText.type === "bot" &&
                      ["We found a workflow related to your query"].includes(
                        chatText?.message
                      ) && (
                        <Button
                          variant="contained"
                          size="medium"
                          startIcon={<SupportAgentOutlinedIcon />}
                          onClick={handleStartWorkflow}
                          sx={{ textTransform: "none", marginLeft: "0.5rem" }}
                        >
                          {workflowName
                            ? `Execute ${chatText?.button}`
                            : "Execute Workflow"}
                        </Button>
                      )}
                  </Message>
                )
              )}
            {(isBotTyping || (conversation[conversation?.length-1]?.message === waitingMsg )) && (
              <Message key="bot-typing" subtype="received">
                <BotAvatar />
                <Reply style={{ padding: "0.5rem 1.5rem" }} subtype="received">
                  <LoadingDot />
                  <LoadingDot />
                  <LoadingDot style={{ margin: 0 }} />
                </Reply>
              </Message>
            )}

            <div style={{ padding: "1rem 0.5rem" }} ref={messagesEndRef} />
            {renderDynamicForm && <DynamicChatForm />}
          </Conversations>
        </Scrollbars>
      </Container>
    </Wrapper>
  );
};

// Exports:
export default ChatConversation;
