// Packages:
import React, { memo } from "react";
import { useSelector } from "react-redux";

// Styles:
import { Body, Description, Field, NodeLabel, NodeWrapper, StyledHandle } from "../styles";
import BaseNode from "../BaseNode";
import { config } from "./config";
import useNodeStyles from "../../../../hooks/useNodeStyles";

// Functions:
const APINode = ({ data }) => {
  // Constants:
  const { id, endpoint,action, label } = data;
  // State:
  const studio = useSelector((state) => state.studio);

  const isSelected = useSelector(
    (state) => state.studio.selectedNode?.id === id
  );

const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})

  // Return:
  return (
    <BaseNode data={data} config={config}>
      <NodeWrapper style={computedWrapperStyle} backgroundColor={'#FEFFFE'} borderColor={'#7721E8'}>
      {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
        <NodeLabel htmlFor="text" style={computedLabelStyle}>
          {label}
        </NodeLabel>
        <Body style={computedBodyStyle}>
          <Description>
            Uses HTTP {" "}
            <Field isActive={studio.selectedNode?.id === id} activeColor={'#491094'} inActiveColor={'#7721E8'}>
              {action&& (
                <span style={{ fontStyle: "italic", fontWeight: 400 }}>
                  {action}
                </span>
              ) }
            </Field>{"Method and"}
          </Description>
          <Description>
            Sends Request to{" "}
            <Field isActive={studio.selectedNode?.id === id} activeColor={'#491094'} inActiveColor={'#7721E8'}>
              {endpoint.length === 0 ? (
                <span style={{ fontStyle: "italic", fontWeight: 400 }}>
                  endpointName
                </span>
              ) : endpoint.length >= 25 ? (
                `${endpoint.slice(0, 25).trim()}..`
              ) : (
                endpoint
              )}
            </Field>{" "}
            endpoint
          </Description>
        </Body>
      </NodeWrapper>
    </BaseNode>
  );
};

// Exports:
export default memo(APINode)
