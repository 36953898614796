import {
  createChannel,
  sendMessage,
  signIn,
  verifyCode,
  createConnection,
  archiveChannel,
  createTicket,
  nameMentions,
  addFeedbackAPI,
  processOtp,
  createTicket_V2,
  createAuthTicket,
} from "../../api";
import { addConversation, setBotTyping } from "./chatActions";

export const authActions = {
  SIGN_IN_ATTEMPT_BEGIN: 'SIGN_IN_ATTEMPT_BEGIN',
  SIGN_IN_ATTEMPT_SUCCESS_TRACK: 'SIGN_IN_ATTEMPT_SUCCESS_TRACK',
  SIGN_IN_ATTEMPT_ERROR: 'SIGN_IN_ATTEMPT_ERROR',
  OTP_VERIFY_BEGIN: 'OTP_VERIFY_BEGIN',
  OTP_VERIFY_SUCCESS: 'OTP_VERIFY_SUCCESS',
  OTP_VERIFY_ERROR: 'OTP_VERIFY_ERROR',
  CREATE_CHANNEL_BEGIN: 'CREATE_CHANNEL_BEGIN',
  CREATE_CHANNEL_SUCCESS: 'CREATE_CHANNEL_SUCCESS',
  CREATE_CHANNEL_ERROR: 'CREATE_CHANNEL_ERROR',
  CREATE_CONNECTION_BEGIN: 'CREATE_CONNECTION_BEGIN',
  CREATE_CONNECTION_SUCCESS: 'CREATE_CONNECTION_SUCCESS',
  CREATE_CONNECTION_ERROR: 'CREATE_CONNECTION_ERROR',
  ARCHIVE_CHANNEL_BEGIN: 'ARCHIVE_CHANNEL_BEGIN',
  ARCHIVE_CHANNEL_SUCCESS: 'ARCHIVE_CHANNEL_SUCCESS',
  ARCHIVE_CHANNEL_ERROR: 'ARCHIVE_CHANNEL_ERROR',
  SET_CHANNEL_ID: 'SET_CHANNEL_ID',
  SET_SOCKET_URL: 'SET_SOCKET_URL',
  SEND_MESSAGE_BEGIN: 'SEND_MESSAGE_BEGIN',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_ERROR: 'SEND_MESSAGE_ERROR',
  SET_LAST_MESSAGE: 'SET_LAST_MESSAGE',
  TICKET_CREATION_BEGIN: 'TICKET_CREATION_BEGIN',
  TICKET_CREATION_SUCCESS: 'TICKET_CREATION_SUCCESS',
  TICKET_CREATION_ERROR: 'TICKET_CREATION_ERROR',
  GET_REPLIES_BEGIN: 'GET_REPLIES_BEGIN',
  GET_REPLIES_SUCCESS: 'GET_REPLIES_SUCCESS',
  GET_REPLIES_ERROR: 'GET_REPLIES_ERROR',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  SET_AUTH_STATUS: 'SET_AUTH_STATUS',
  SET_USER_STATUS: 'SET_USER_STATUS',
  REFRESH_SESSION: 'REFRESH_SESSION',
  SET_AGENT_CUSTOMER_CHAT_STATUS: 'SET_AGENT_CUSTOMER_CHAT_STATUS'
};
let validation_timeout = null;

export const getAuthActions = (dispatch) => {
  return {
    signInUser: (email, org_id) => dispatch(signInUser(email, org_id)),
    otpVerification: (
      email,
      session,
      answer,
      organisation_info,
      user,
      useSlack
    ) =>
      dispatch(
        otpVerification(
          email,
          session,
          answer,
          organisation_info,
          user,
          useSlack
        )
      ),
    creatingChannel: () => dispatch(creatingChannel()),
    sendingMessage: (message, id) => dispatch(sendingMessage(message, id)),
    archivingChannel: (id) => dispatch(archivingChannel(id)),
    creatingTicket: (comment) => dispatch(creatingTicket(comment))
  };
};

export const setUserDetails = (payload) => {
  return {
    type: authActions.SET_USER_DETAILS,
    payload
  };
};
export const setAgentCustomerChatStatus = (payload) => {
  return {
    type: authActions.SET_AGENT_CUSTOMER_CHAT_STATUS,
    payload
  };
};

export const setAuthenticationStatus = (payload) => {
  return {
    type: authActions.SET_AUTH_STATUS,
    payload
  };
};

export const setLastMessage = (message) => {
  return {
    type: authActions.SET_LAST_MESSAGE,
    payload: message
  };
};

export const setUserStatus = (payload) => {
  return {
    type: authActions.SET_USER_STATUS,
    payload
  };
};

const signInUser = (email, org_id) => {
  localStorage.setItem('validation_attempts', 0);
  localStorage.setItem('validation_comments', '');
  localStorage.setItem("isTemporaryUserActive", false);
  return async (dispatch) => {
    if (localStorage.getItem('validate_enduser') === 'true') {
      dispatch({ type: authActions.SIGN_IN_ATTEMPT_BEGIN });
      dispatch(setBotTyping(true));
      try {
        localStorage.setItem("isTemporaryUserActive", false);
        const user = await signIn(email, org_id);
        if ( user['data']['data'] ===
        'DefineAuthChallenge failed with error User does not exist.') {
          const operation = "send-otp";
          const res = await processOtp(email, operation);
          const user = {
            statusCode : res?.status,
            statusCode : res?.status,   // WHY 2
            email : res?.data?.email,
          }
          localStorage.setItem("isTemporaryUserActive", true);
          dispatch(
            addConversation({
              type: "bot",
              subtype: "received",
              message: `Please enter the 4-digit FastTrack Login Code we emailed you from info@readyly.com`,
            })
          );
          dispatch({
            type: authActions.SIGN_IN_ATTEMPT_SUCCESS_TRACK,
            payload: user,
          });
          validation_timeout = setTimeout(function () {
            localStorage.setItem("unauthenticated_user", email);
            dispatch(
              addConversation({
              type: 'bot',
              subtype: 'received',
              message: `Our agents are unavailable, but this chat conversation will be forwarded to our team. Leave any additional details here before closing the chat. We'll respond by email within a few hours during our normal business days. Thank you!`
              })
            );
            localStorage.setItem(
              "validation_attempts",
              parseInt(localStorage.getItem("validation_attempts")) + 1
            );
            localStorage.setItem(
              "validation_comments",
              localStorage.getItem("validation_comments") +
                "Attempt " +
                parseInt(localStorage.getItem("validation_attempts")) +
                ": Otp not provided within 3 minutes\n"
            );
          }, 180000);
          dispatch(setBotTyping(false));
        } else {
          localStorage.setItem("isTemporaryUserActive", false);
          dispatch(
            addConversation({
              type: 'bot',
              subtype: 'received',
              message: `Please enter the 4-digit FastTrack Login Code we emailed you from info@readyly.com`
            })
          );
          dispatch({
            type: authActions.SIGN_IN_ATTEMPT_SUCCESS_TRACK,
            payload: user,
          });
          validation_timeout = setTimeout(function () {
            localStorage.setItem('unauthenticated_user', email);
            dispatch(
              addConversation({
                type: 'bot',
                subtype: 'received',
                message:
                  "We haven't received your code. Please leave a message, and we'll get back to you by the email address you provided. Click “Leave Chat” or close your browser tab when done. Thanks!"
              })
            );
            localStorage.setItem(
              'validation_attempts',
              parseInt(localStorage.getItem('validation_attempts')) + 1
            );
            localStorage.setItem(
              'validation_comments',
              localStorage.getItem('validation_comments') +
                'Attempt ' +
                parseInt(localStorage.getItem('validation_attempts')) +
                ': Otp not provided within 3 minutes\n'
            );
          }, 180000);
          dispatch(setBotTyping(false));
        }
      } catch (error) {
        if (error?.message !== 'User does not exist') {
          dispatch(
            addConversation({
              type: 'bot',
              subtype: 'received',
              message: error.message
            })
          );
          dispatch({
            type: authActions.SIGN_IN_ATTEMPT_ERROR,
            payload: error?.response?.data?.message
          });
        }
        throw error;
      }
    } else {
      localStorage.setItem('validated_user', email);
      dispatch(creatingChannel());
    }
  };
};

const otpVerification = (
  email,
  session,
  answer,
  organisation_info,
  user,
  useSlack
) => {
  return async (dispatch) => {
    dispatch({ type: authActions.OTP_VERIFY_BEGIN });
    try {
      //let attempts=3;
      clearTimeout(validation_timeout);
      const isTemporaryUserActive = localStorage.getItem("isTemporaryUserActive")==='true';
      if(isTemporaryUserActive){
        const operation="verify-otp"
        const returnedUser = await processOtp(email,operation, answer)
        if (returnedUser?.data?.statusCode===401 || returnedUser?.data?.statusCode===403) {
          dispatch(
            addConversation({
              type: 'bot',
              subtype: 'received',
              message: `Invalid code. Please try again.`
            })
          );
          dispatch(setBotTyping(false));
          localStorage.setItem(
          'validation_attempts',
          parseInt(localStorage.getItem('validation_attempts')) + 1
          );
          localStorage.setItem(
          'validation_comments',
          localStorage.getItem('validation_comments') +
            'Attempt ' +
            parseInt(localStorage.getItem('validation_attempts')) +
            ': All otp attempts exhausted\n'
          );
          throw new Error('Invalid otp');
        }
        else {
        localStorage.setItem('validated_user', email);
          if (useSlack) dispatch(creatingChannel());
        }
        dispatch({ type: authActions.OTP_VERIFY_SUCCESS });
      if (user?.role === 'endUser') {
        dispatch(
          setUserStatus({
            ...user,
            isAuthenticated: true
          })
        );
      }
      }
      else{
        const returnedUser = await verifyCode(email, session, answer);

        if (returnedUser["data"]["data"]["AuthenticationResult"] === undefined) {
          
          if (
            returnedUser["data"]["data"] ===
            "DefineAuthChallenge failed with error Invalid OTP."
          ) {
            localStorage.setItem("unauthenticated_user", email);
            dispatch(
              addConversation({
              type: 'bot',
              subtype: 'received',
              message: `Unfortunately, you have exhausted all attempts. Please leave a message, and we'll get back to you by the email address you provided. Click “Leave Chat” or close your browser tab when done. Thanks!`
              })
            );
            localStorage.setItem(
            'validation_attempts',
            parseInt(localStorage.getItem('validation_attempts')) + 1
            );
            localStorage.setItem(
            'validation_comments',
            localStorage.getItem('validation_comments') +
              'Attempt ' +
              parseInt(localStorage.getItem('validation_attempts')) +
              ': All otp attempts exhausted\n'
            );
          throw new Error('Exhausted All attempts');
          } else {
            dispatch({
              type: authActions.REFRESH_SESSION,
            payload: returnedUser
            });
            dispatch(
              addConversation({
              type: 'bot',
              subtype: 'received',
              message: `Invalid code. Please try again.`
              })
            );
            dispatch(setBotTyping(false));
            localStorage.setItem(
            'validation_attempts',
            parseInt(localStorage.getItem('validation_attempts')) + 1
            );
            localStorage.setItem(
            'validation_comments',
            localStorage.getItem('validation_comments') +
              'Attempt ' +
              parseInt(localStorage.getItem('validation_attempts')) +
              ': Invalid otp entered\n'
            );
          throw new Error('Invalid otp');
          }
        } else {
          // create channel
          //window.location.reload(true)
          //console.log(returnedUser)
        localStorage.setItem('validated_user', email);
          if (useSlack) dispatch(creatingChannel());  
        }
        dispatch({ type: authActions.OTP_VERIFY_SUCCESS });
      if (user?.role === 'endUser') {
        dispatch(
          setUserStatus({
            ...user,
            isAuthenticated: true
          })
        );
      }
      }
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        dispatch(
          addConversation({
          type: 'bot',
          subtype: 'received',
          message: `Our agents are unavailable, but this chat conversation will be forwarded to our team. Leave any additional details here before closing the chat. We'll respond by email within a few hours during our normal business days. Thank you!`
          })
        );
        dispatch(setBotTyping(false));
      }
      else if (
        error?.message !== 'Exhausted All attempts' &&
        error?.message !== 'Invalid otp'
      ) {
        dispatch(
          addConversation({
            type: 'bot',
            subtype: 'received',
            message: error.message
          })
        );
        dispatch({
          type: authActions.OTP_VERIFY_ERROR,
          payload: error?.response?.data?.message
        });
        dispatch(setBotTyping(false));
      }
      throw error;
    }
  };
};

function creatingChannel() {
  return async (dispatch, getState) => {
    dispatch({ type: authActions.CREATE_CHANNEL_BEGIN });
    try {
      const response = await createChannel(
        localStorage.getItem('org_name'),
        localStorage.getItem('validated_user')
      );
      //console.log(response['data']['body']);
      if (response['data']['statusCode'] !== 400) {
        dispatch({
          type: authActions.SET_CHANNEL_ID,
          payload: response
        });
        dispatch(
          addConversation({
            type: 'bot',
            subtype: 'received',
            message: 'Please wait, connecting you to a live agent!'
          })
        );
        dispatch({
          type: authActions.CREATE_CHANNEL_SUCCESS
        });
        dispatch(creatingConnection(response['data']['body']));
      } else {
        dispatch(
          addConversation({
            type: 'bot',
            subtype: 'received',
            message: response['data']['body']
          })
        );

        addFeedbackAPI(
          getState().content.org_info?.org_data?._id,
          `${getState().auth.userDetails?.email || 'anonymous'}`,
          0,
          'All agents are either offline or with another customer',
          'Agent Unavailable'
        );

        localStorage.setItem(
          'validation_attempts',
          parseInt(localStorage.getItem('validation_attempts')) + 1
        );
        localStorage.setItem(
          'validation_comments',
          localStorage.getItem('validation_comments') +
            'Attempt ' +
            parseInt(localStorage.getItem('validation_attempts')) +
            ': All agents are offline\n'
        );
      }
    } catch (error) {
      dispatch(
        addConversation({
          type: 'bot',
          subtype: 'received',
          message: error.message
        })
      );
      addFeedbackAPI(
        getState().content.org_info?.org_data?._id,
        `${getState().auth.userDetails?.email || 'anonymous'}`,
        0,
        'All agents are either offline or with another customer',
        'Agent Unavailable'
      );
      dispatch({
        type: authActions.CREATE_CHANNEL_ERROR,
        payload: error?.response?.data?.message
      });
    }
  };
}
//if agent doesn't reply in 30s close the chat
function creatingConnection(channelId) {
  localStorage.setItem('slack_channel_id', channelId);
  return async (dispatch, getState) => {
    dispatch({ type: authActions.CREATE_CONNECTION_BEGIN });
    try {
      //console.log(channelId)
      // get socket Url
      const response = await createConnection(channelId);
      var socketUrl = response['data']['body']['url'];
      var ok = response['data']['body']['ok'];
      if (ok) {
        dispatch({
          type: authActions.SET_SOCKET_URL,
          payload: response
        });
        // Create WebSocket connection.
        const socket = new WebSocket(socketUrl);
        let timeout = null;
        let agent_reply_timeout = localStorage.getItem('agent_reply_timeout');
        // Connection opened
        socket.addEventListener('open', function (event) {
          //socket.send('Hello Server!');
          dispatch(
            addConversation({
              type: 'bot',
              subtype: 'received',
              message: 'Thank You! You are now connected to our support.'
            })
          );
          if (localStorage.getItem('AIchats') !== null) {
            dispatch(
              sendingMessage(localStorage.getItem('AIchats'), channelId)
            );
          }
          timeout = setTimeout(function () {
            if (localStorage.getItem('slack_channel_id') !== null) {
              dispatch(
                addConversation({
                  type: 'bot',
                  subtype: 'received',
                  message:
                    "Our agents are either offline or with another customer. You can wait for an agent to respond, or leave a message in the chat and we'll get back to you by email."
                })
              );
            }
            addFeedbackAPI(
              getState().content.org_info?.org_data?._id,
              `${getState().auth.userDetails?.email || 'anonymous'}`,
              0,
              'All agents are either offline or with another customer',
              'Agent Unavailable'
            );
          }, agent_reply_timeout);
        });

        socket.addEventListener('close', function (event) {
          if (localStorage.getItem('slack_channel_id') !== null) {
            dispatch(
              archivingChannel(localStorage.getItem('slack_channel_id'))
            );
            dispatch(
              addConversation({
                type: 'bot',
                subtype: 'received',
                message:
                  'Everyone has left the chat and the conversation is marked as closed.'
              })
            );
          }
        });

        // Listen for messages
        socket.addEventListener('message', function (event) {
          var result = JSON.parse(event.data);
          try {
            if (
              result['payload'].event['type'] === 'message' &&
              result['payload'].event['channel'] === channelId &&
              result['payload'].event['user'] !==
                response['data']['channelInfo']['channel']['creator'] &&
              result['retry_attempt'] === 0
            ) {
              clearTimeout(timeout);
              if (
                result['payload'].event['text'].includes('@') &&
                result['payload'].event['text'].includes('<') &&
                result['payload'].event['text'].includes('>')
              ) {
                let mentionedId = result['payload'].event['text']
                  .slice(
                    result['payload'].event['text'].indexOf('<') + 1,
                    result['payload'].event['text'].indexOf('>')
                  )
                  .replace('@', '');
                nameMentions(mentionedId).then(function (data) {
                  let mentionedData =
                    data['data']['userInfo']['user']['profile']['real_name'];
                  let final_message = result['payload'].event['text']
                    .replace(mentionedId, mentionedData)
                    .replace('<', '')
                    .replace('>', '');
                  dispatch(
                    addConversation({
                      type: 'agent',
                      subtype: 'received',
                      message: final_message
                    })
                  );
                });
              } else if (
                result['payload'].event['text'].includes('<') &&
                result['payload'].event['text'].includes('>') &&
                (result['payload'].event['text'].includes('http') ||
                  result['payload'].event['text'].includes('www'))
              ) {
                let url = result['payload'].event['text'].slice(
                  result['payload'].event['text'].indexOf('<') + 1,
                  result['payload'].event['text'].indexOf('>')
                );
                let final_message = result['payload'].event['text']
                  .replace('<', '')
                  .replace('>', '')
                  .replace(url, `<a href="${url}" target="_blank">${url}</a>`);
                dispatch(
                  addConversation({
                    type: 'agent',
                    subtype: 'received',
                    message: final_message
                  })
                );
              } else {
                dispatch(
                  addConversation({
                    type: 'agent',
                    subtype: 'received',
                    message: result['payload'].event['text']
                  })
                );
              }
              //console.log(result);
            }
            if (
              result['payload'].event['type'] === 'member_left_channel' &&
              result['payload'].event['channel'] === channelId &&
              result['payload'].event['user'] !==
                response['data']['channelInfo']['channel']['creator'] &&
              result['retry_attempt'] === 0
            ) {
              let mentionedId = result['payload'].event['user'];
              nameMentions(mentionedId).then(function (data) {
                let mentionedData =
                  data['data']['userInfo']['user']['profile']['real_name'];
                let final_message = mentionedData + ' has left the chat';
                dispatch(
                  addConversation({
                    type: 'agent',
                    subtype: 'received',
                    message: final_message
                  })
                );
              });
            }
          } catch (err) {}
        });
        dispatch({
          type: authActions.CREATE_CONNECTION_SUCCESS
        });
      } else {
        dispatch(
          addConversation({
            type: 'bot',
            subtype: 'received',
            message: 'Failed to connect.'
          })
        );
      }
    } catch (error) {
      dispatch(
        addConversation({
          type: 'bot',
          subtype: 'received',
          message: error.message
        })
      );

      dispatch({
        type: authActions.CREATE_CONNECTION_ERROR,
        payload: error?.response?.data?.message
      });
    }
  };
}

const sendingMessage = (message, id) => {
  return async (dispatch) => {
    dispatch({ type: authActions.SEND_MESSAGE_BEGIN });
    try {
      const user = await sendMessage(message, id);
      dispatch({
        type: authActions.SEND_MESSAGE_SUCCESS,
        payload: user
      });
    } catch (error) {
      dispatch(
        addConversation({
          type: 'bot',
          subtype: 'received',
          message: error.message
        })
      );
      dispatch({
        type: authActions.SEND_MESSAGE_ERROR,
        payload: error?.response?.data?.message
      });
    }
  };
};

export const archivingChannel = (id) => {
  return async (dispatch) => {
    dispatch({ type: authActions.ARCHIVE_CHANNEL_BEGIN });
    try {
      const user = await archiveChannel(id);
      dispatch({
        type: authActions.ARCHIVE_CHANNEL_SUCCESS,
        payload: user
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: authActions.ARCHIVE_CHANNEL_ERROR,
        payload: error?.response?.data?.message
      });
    }
  };
};

export const creatingTicket = (
  comment,
  ticketing_system,
  validated_email,
  record_id,
  conv_type,
  isAuthTicket,
  org_id,
  agent_type_info
) => {
  comment = comment.replaceAll("null","")
  return async (dispatch) => {
    dispatch({ type: authActions.TICKET_CREATION_BEGIN });
    try {
      let ticketCreationFunction = createTicket;
      let user = await ticketCreationFunction(
        comment,
        ticketing_system,
        validated_email,
        record_id,
        conv_type,
        org_id,
        agent_type_info
      );
      dispatch({
        type: authActions.TICKET_CREATION_SUCCESS,
        payload: user
      });
      if (isAuthTicket) {
        const user2 = await createTicket_V2(
          comment,
          ticketing_system,
          validated_email,
          record_id,
          conv_type,
          org_id,
          agent_type_info
        );
        dispatch({
          type: authActions.TICKET_CREATION_SUCCESS,
          payload: user2
        });
      }
    } catch (error) {
      dispatch(
        addConversation({
          type: 'bot',
          subtype: 'received',
          message: error.message
        })
      );
      dispatch({
        type: authActions.TICKET_CREATION_ERROR,
        payload: error?.response?.data?.message
      });
    }
  };
};

export const creatingTicket_V2 = (
  comment,
  ticketing_system,
  validated_email,
  record_id,
  conv_type,
  org_id,
  feedback,
  senderEmail,
  supportEmail,
  agent_type_info
) => {
  comment = comment.replaceAll("null","")
  return async (dispatch) => {
    dispatch({ type: authActions.TICKET_CREATION_BEGIN });
    try {
      const user = await createTicket_V2(
        comment,
        ticketing_system,
        validated_email,
        record_id,
        conv_type,
        org_id,
        feedback,
        senderEmail,
        supportEmail,
        agent_type_info
      );
      dispatch({
        type: authActions.TICKET_CREATION_SUCCESS,
        payload: user
      });
    } catch (error) {
      dispatch(
        addConversation({
          type: 'bot',
          subtype: 'received',
          message: error.message
        })
      );
      dispatch({
        type: authActions.TICKET_CREATION_ERROR,
        payload: error?.response?.data?.message
      });
    }
  };
};
