// Packages:
import React, { memo } from "react";
import {  useSelector } from "react-redux";

// Styles:
import { Body, Description, NodeLabel, NodeWrapper, StyledHandle } from "../styles";
import BaseNode from "../BaseNode";
import { config } from "./config";
import useNodeStyles from "../../../../hooks/useNodeStyles";
// Functions:
const ParallelHandler = ({ data }) => {
  // Constants:
  const { id, orderId, decisionType, label } = data;
  const isSelected = useSelector(
    (state) => state.studio.selectedNode?.id === id
  );

  const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})

  // State:
  const studio = useSelector((state) => state.studio);
  // Return:
  return (
    <BaseNode data={data} config={config}>
      <NodeWrapper style={computedWrapperStyle} backgroundColor={'#FEFFFE'} borderColor={'#7A5980'}>
      {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
        <NodeLabel htmlFor="text" style={computedLabelStyle}>
          {label}
        </NodeLabel>
        <Body style={computedBodyStyle}>
          <Description>
          </Description>
        </Body>
      </NodeWrapper>
    </BaseNode>
  );
};

// Exports:
export default memo(ParallelHandler);
