// Packages:
import React, { memo } from "react";
import { useSelector } from "react-redux";
// Styles:
import { Body, Description, Field, NodeLabel, NodeWrapper, StyledHandle } from "../styles";
import BaseNode from "../BaseNode";
import { config } from "./config";
import useNodeStyles from "../../../../hooks/useNodeStyles";


// Functions:
const MessageNode = ({ data }) => {
  // Constants:
  const { id, label, message } = data;

  // State:
  const studio = useSelector((state) => state.studio);
  const isSelected = useSelector(
    (state) => state.studio.selectedNode?.id === id
  );

  const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})

  // Return:
  return (
    <BaseNode data={data} config={config}>
      <NodeWrapper style={computedWrapperStyle}backgroundColor={'#FEFFFE'} borderColo={'#401FFF'}>
      {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
        <NodeLabel htmlFor="text" style={computedLabelStyle}>
          {label}
        </NodeLabel>
        <Body>
          <Description>
            <Field isActive={studio.selectedNode?.id === id} activeColor={'#1C00B8'} inActiveColor={'#401FFF'}>
              {message.length === 0 ? (
                <span style={{ fontStyle: "italic", fontWeight: 400 }}>
                  Write a message...
                </span>
              ) : message.length >= 30 ? (
                `${message.slice(0, 25).trim()}..`
              ) : (
                message
              )}
            </Field>
          </Description>
        </Body>
      </NodeWrapper>
    </BaseNode>
  );
};

// Exports:
export default memo(MessageNode);
