import React, { memo, useCallback, useMemo } from "react";
import { Autocomplete, TextField, styled } from "@mui/material";
import { FieldName } from "./styles";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const AutoCompleteGlobal = ({
  Title,
  dynamicFields,
  data,
  setData,
  DEFAULT_OUTPUT,
  isEditable,
}) => {

  const handleAutocompleteChange = useCallback((event, value) => {
    if (!value) {
      setData(DEFAULT_OUTPUT);
      return;
    }

    const isDynamic = dynamicFields.includes(value);
    const newValue = isDynamic
      ? { fieldName: value, value: null }
      : { fieldName: null, value };

    setData({ key: DEFAULT_OUTPUT.key, value: newValue });
  },[dynamicFields,DEFAULT_OUTPUT,setData]);

  return (
    <>
      <FieldName>{Title}</FieldName>
      <Autocomplete
        options={dynamicFields}
        disabled={!isEditable}
        value={data?.value?.fieldName ?? data?.value?.value}
        fullWidth
        freeSolo
        onChange={handleAutocompleteChange}
        onInputChange={handleAutocompleteChange}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            placeholder="Select or Enter Value"
            variant="outlined"
            size="small"
            style={{ padding: 0 }}
            sx={{textTransform: "none",mt: "0.25rem",}}
          />
        )}
      />
    </>
  );
};

export default memo(AutoCompleteGlobal);
