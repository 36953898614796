import React, { memo, useCallback, useEffect, useState } from 'react'
import { getExecutionList } from '../../../pages/StudioV2/utils/api'

import Skeleton from 'react-loading-skeleton';
import { ExecutionListContainer, FieldName, LastModified, LoadMoreButton, Name, Workflow } from './styles'
import { FormControl, MenuItem, Select, styled } from '@mui/material';
import ExecutionListItem from './ExecutionListItem';


const StyledFormControl = styled(FormControl)`
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const STATUSFILTER = {
    ALL:'ALL',
    FAILED:'FAILED',
    SUCCEEDED:'SUCCEEDED',
    ABORTED:'ABORTED',
    TIMED_OUT:'TIMED_OUT'
  };

const ExecutionList = ({stateMachineArn}) => {
    const [executionList, setExecutionList] = useState({ data: { executions: [], nextToken: null } });
    const [nextToken,setNextToken]=useState(null)
    const [statusFilter,setStatusFilter]=useState('ALL')
    const [isLoadingExecutionList,setIsLoadingExecutionList]=useState(false)
    const orgId = localStorage.getItem("org_id");

    const fetchExecutionList=async(stateMachineArn,statusFilter,nextToken=null)=>{
        const executionList=await getExecutionList(stateMachineArn,statusFilter,nextToken)
        return executionList
       }
    
    const handleExecutionClicked=(e,arn)=>{
       console.log("to be implemented after lists")
      }   

      useEffect(() => {
        const fetchExecutions = async () => {
            setIsLoadingExecutionList(true);
            if (orgId) {
                const filter = statusFilter === 'ALL' ? null : statusFilter;
                const fetchedList = await fetchExecutionList(stateMachineArn, filter, nextToken);
                updateExecutionList(fetchedList);
            }
            setIsLoadingExecutionList(false);
        };
    
        fetchExecutions();
    }, [nextToken, statusFilter]);
    
    const updateExecutionList = (fetchedList) => {
        const newExecutions = filterNewExecutions(fetchedList?.data?.executions);
        const mergedExecutions = mergeExecutions(newExecutions);
        setExecutionList(prev => ({
            data: {
                executions: mergedExecutions,
                nextToken: fetchedList?.data?.nextToken
            }
        }));
    };
    
    const filterNewExecutions = (newExecutions) => {
        return newExecutions?.filter(newExecution => 
            !executionList?.data?.executions.some(existingExecution => 
                existingExecution?.executionArn === newExecution?.executionArn
            )
        );
    };
    
    const mergeExecutions = (newExecutions) => {
        return [...executionList?.data?.executions, ...newExecutions];
    };

    const handleExecution=()=>{
        setNextToken(executionList?.data?.nextToken)
    }  

    const handleStatusFilterChange = useCallback((event) => {
      setStatusFilter(event.target.value);
      setExecutionList({ data: { executions: [], nextToken: null } }); 
  }, []);

  return (
    <ExecutionListContainer>
    {isLoadingExecutionList ? (
        <Workflow isFocused={true}>
            <Name>
                <Skeleton count={0.75} />
            </Name>
            <LastModified>
                <Skeleton count={0.5} />
            </LastModified>
        </Workflow>
    ) : (
        <>
            <FieldName>Status filter</FieldName>
            <StyledFormControl size="small" fullWidth>
                <Select
                    id="status-filter"
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                >
                    {Object.values(STATUSFILTER).map((status, index) => (
                        <StyledMenuItem key={index} value={status}>
                            {status}
                        </StyledMenuItem>
                    ))}
                </Select>
            </StyledFormControl>
            {executionList?.data?.executions?.map((execution, index) => (
                <ExecutionListItem
                    key={execution.executionArn}
                    execution={execution}
                    handleExecutionClicked={handleExecutionClicked}
                />
            ))}
        </>
    )}
    {executionList?.data?.nextToken&& (
        <LoadMoreButton onClick={handleExecution}>
            Load More
        </LoadMoreButton>
    )}
</ExecutionListContainer>

  )
}

export default memo(ExecutionList)