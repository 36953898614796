import { TextField } from "@mui/material";
import React, { memo, useState } from "react";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const InputPair = ({ item, outputs, output, updateOutput, isEditable }) => {
  const [field, setField] = useState(item?.field);
  const [message, setMessage] = useState(item?.message);

  // Functions
  const putNewUpdateInSameIndex = (update) => {
    const outputIndex = outputs.findIndex((val) => val.id === output.id);
    const newOutputs = outputs.filter((val) => val.id !== output.id);

    const fieldIndex = output.fields.findIndex((field) => field.id === item.id);
    const tempFields = output.fields.filter((val) => val.id !== item.id);
    tempFields.splice(fieldIndex, 0, update);

    const newOutput = { ...output, fields: tempFields };
    newOutputs.splice(outputIndex, 0, newOutput);
    updateOutput(newOutputs);
  };

  const onFieldChange = (event) => {
    setField(event.target.value);
    const update = {
      id: item.id,
      message,
      type: item?.type,
      field: event.target.value,
    };

    putNewUpdateInSameIndex(update);
  };

  const onMessageChange = (event) => {
    setMessage(event.target.value);
    const update = {
      id: item.id,
      field,
      type: item?.type,
      message: event.target.value,
    };
    putNewUpdateInSameIndex(update);
  };

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <StyledTextField
        type="text"
        variant="outlined"
        disabled={!isEditable}
        placeholder="Name"
        value={field}
        onChange={(event) => onFieldChange(event)}
        size="small"
        sx={{ marginBottom: "0" }}
      />
      <StyledTextField
        type="text"
        variant="outlined"
        disabled={!isEditable}
        placeholder="Message"
        value={message}
        onChange={(event) => onMessageChange(event)}
        size="small"
        fullWidth
        sx={{ marginBottom: "0" }}
      />
    </div>
  );
};

export default memo(InputPair);
