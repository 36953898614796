// Packages:
import React, { memo, useCallback } from "react";
import { Position } from "react-flow-renderer";
import './styles.css'

// Styles:
import { Wrapper, Title } from "./styles";
import Scrollbars from "react-custom-scrollbars-2";
import { NodeLabel, NodeWrapper } from "../Nodes/styles";

// Constants:
const DEFAULT_TEMPLATE = `{
  "_success": {
    "_on": "true",
    "_output": {
      "variableName": "res.body.variableName"
    }
  },
  "_failure": {
    "_on": "res.status != 200",
    "_output": {
      "error": "res.error || res.status.error"
    }
  }
}`;

const centredText = () => {
  return { display: "flex", alignItems: "center", justifyContent: "center" };
};
const commonNodeStyles = () => {
  return {
    marginBottom: "1rem",
    paddingBottom: "0.5rem",
    textAlign: "center",
    cursor: "grab",
  };
};

const nodes = [
  { type: "start",nodeType:'', borderColor: "#3AA640", data: { comment: "", tags: [],label: "START" } },
  { type: "acuity",nodeType:'api', borderColor: "#008060", data: { action: "GET AVAILABILITY", label: "ACUITY" } },
  { type: "api",nodeType:'', borderColor: "#7721E8", data: { action: "GET", endpoint: "", label: "API" } },
  { type: "choice",nodeType:'', borderColor: "#7721e8", data: { label: "CHOICE"} },
  { type: "decision",nodeType:'', borderColor: "#FE7920", data: { orderId: "", decisionType: "BINARY", options: [],label: "DECISION" } },
  { type: "email",nodeType:'', borderColor: "#D43558", data: {label: "EMAIL"} },
  { type: "form",nodeType:'', borderColor: "#4F5450", data: { formName: "", formDescription: "", label: "FORM" } },
  { type: "google_sheets",nodeType:'api', borderColor: "#188038", data: { operation: "create", sheet_name: "", sheet_baseurl: "", credentials: "", data: [], filter: [],label: "GOOGLE SHEETS" } },
  { type: "logistics",nodeType:'api',  borderColor: "#FE7920", data: { userId: '' ,label: "LOGISTICS"} },
  { type: "message",nodeType:'', borderColor: "#401FFF", data: { message: "", formName: "", expectResponse: false, responsesExpected: 0, outputs: [], options: [],label: "MESSAGE" } },
  { type: "parallel",nodeType:'',  borderColor: "#FE7920", data: {label: "PARALLEL"} },
  { type: "parallelHandler",nodeType:'', borderColor: "#FE7920", data: { label: "PARALLEL END"} },
  { type: "ai",nodeType:'', borderColor: "#7721E8", data: { action: "GENERATE_AI_RESPONSE", label: "READYLY AI" } },
  { type: "shipping",nodeType:'api', borderColor: "#747458", data: { carrier: "SHIPPING EASY", shippingEasyAction: "order_details", shippingType: "FIRST CLASS MAIL", label: "SHIPPING"} },
  { type: "shopify",nodeType:'api', borderColor: "#008060", data: { shopifyAction: "order_fulfillment_details", label: "SHOPIFY" } },
  { type: "ticket",nodeType:'', borderColor: "#235789", data: { ticketChannel: "ZENDESK", ticketAction: "CREATE", tags: [], informationToExtract: [], macros: [], includedSearchTerms: [], excludedSearchTerms: [], customFields: "", channel: "NONE", status: "NONE", searchFilter: { status: [] }, label: "TICKET", } },
  { type: "ticketure",nodeType:'api', borderColor: "#235789", data: { label: "TICKETURE"} },
  { type: "leaf",nodeType:'', borderColor: "#DB4C40", data: { handlePosition: Position.Top, isEndNode: true, label: "END" } },
];

const Assets = () => {
  // Functions:
  const onDragStart = useCallback((event, nodeType,workflowType, data) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/workflowType", workflowType);
    event.dataTransfer.setData("data", JSON.stringify(data));
    event.dataTransfer.effectAllowed = "move";
  }, []);

  return (
    <Wrapper>
      <Scrollbars>
        <Title>Asset</Title>
        {nodes.map((node, index) => (
          <NodeWrapper
            key={node.type}
            style={{
              ...commonNodeStyles(),
              border: `2px solid ${node.borderColor}`,
              ...centredText(),
            }}
            onDragStart={(event) => onDragStart(event, node.type,node.nodeType, node.data)}
            backgroundColor={'#FEFFFE'}
            borderColor={node.borderColor}
            draggable
          >
            <NodeLabel style={centredText()}>{node.data.label}</NodeLabel>
          </NodeWrapper>
        ))}
      </Scrollbars>
    </Wrapper>
  );
};

export default memo(Assets);