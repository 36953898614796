// Packages:
import React, { useContext, useState } from 'react';
import {
  TextField,
  styled,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import SendIcon from '@mui/icons-material/Send';
import { WebSocketConnectionContext } from '../../../App';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import { Wrapper, Title, Subtitle, Group } from './styles';
import { setBotTyping } from '../../../redux/actions/chatActions';

const StyledTextField = styled(TextField)`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiOutlinedInput-input {
    background-color: #ffffff;
    font-size: 14px;
  }
`;

const StyledFormControl = styled(FormControl)`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiOutlinedInput-root {
    font-size: 14px;
    background-color: #ffffff;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledFormLabel = styled(FormLabel)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

// Functions:
const ChatForm = ({ form, active }) => {
  const dispatch = useDispatch();
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const chat = useSelector((state) => state.chat);
  const [formState, setFormState] = useState(() => {
    let tempState = {};
    form?.formFields?.forEach((single) => (tempState[single?.field] = null));
    return tempState;
  });
  const [formActive, setFormActive] = useState(active);
  const { sendJsonMessage } = useContext(WebSocketConnectionContext);
  const nameFields = form?.formFields?.filter(
    (item) => item.type.toLowerCase() === 'name'
  );
  const addressFields = form?.formFields?.filter(
    (item) => item.type.toLowerCase() === 'address'
  );
  const emailFields = form?.formFields?.filter(
    (item) => item.type.toLowerCase() === 'email'
  );
  const [emailErrorText, setEmailErrorText] = useState('');
  const filteredFields = form?.formFields?.filter(
    (item) =>
      item.type.toLowerCase() === 'string' ||
      item.type.toLowerCase() === 'date'||
      item.type.toLowerCase() === 'time'||
      item.type.toLowerCase() === 'number'||
      item.type.toLowerCase() === 'text'||
      item.type.toLowerCase() === 'email' ||
      item.type.toLowerCase() === 'phone number' ||
      item.type.toLowerCase() === 'boolean' ||
      item.type.toLowerCase() === 'multiple' ||
      item.type.toLowerCase() === 'datetime' ||
      (item.type.toLowerCase() === 'name' &&
        item.field === nameFields[0].field) ||
      (item.type.toLowerCase() === 'address' &&
        item.field === addressFields[0].field)
  );

  const handleSubmit = () => {
    emailFields.map((email) => {
      if (!/\S+@\S+\.\S+/.test(formState[email.field])) {
        setEmailErrorText('Incorrect email');
        setFormActive(true);
      } else setEmailErrorText('');
    });
    if (emailErrorText.length === 0) {
      const sendMsg = {
        action: 'sendMessage',
        connected_to: chat?.incomingMessage?.messageData?.my_connection_id,
        orgid,
        type: 'stateMachineReply',
        message: {
          replies: {}
        }
      };
      sendMsg['message']['replies'] = formState;
      sendJsonMessage(sendMsg);
      setFormActive(false);
    }
  };

  return (
    <Wrapper>
      <Title>{form?.formName ? form?.formName : 'Form'}</Title>
      <Subtitle>Please enter the details and submit</Subtitle>

      {filteredFields.map((data, index) =>
        data?.type?.toLowerCase() === 'name' ? (
          <Group>
            <StyledTextField
              key={index}
              fullWidth
              disabled={!formActive}
              variant="outlined"
              placeholder={nameFields[0]?.message}
              size="small"
              onChange={(e) =>
                setFormState((prev) => {
                  let temp = { ...prev };
                  temp[nameFields[0]?.field] = e.target.value;
                  return temp;
                })
              }
            />

            <StyledTextField
              key={index + 1}
              fullWidth
              disabled={!formActive}
              variant="outlined"
              placeholder={nameFields[1]?.message}
              size="small"
              onChange={(e) =>
                setFormState((prev) => {
                  let temp = { ...prev };
                  temp[nameFields[1]?.field] = e.target.value;
                  return temp;
                })
              }
            />
          </Group>
        ) : data?.type?.toLowerCase() === 'address' ? (
          <>
            <StyledTextField
              key={index}
              fullWidth
              disabled={!formActive}
              variant="outlined"
              placeholder={addressFields[0]?.message}
              size="small"
              onChange={(e) =>
                setFormState((prev) => {
                  let temp = { ...prev };
                  temp[addressFields[0]?.field] = e.target.value;
                  return temp;
                })
              }
            />
            <Group>
              <StyledTextField
                key={index + 1}
                fullWidth
                disabled={!formActive}
                variant="outlined"
                placeholder={addressFields[1]?.message}
                size="small"
                onChange={(e) =>
                  setFormState((prev) => {
                    let temp = { ...prev };
                    temp[addressFields[1]?.field] = e.target.value;
                    return temp;
                  })
                }
              />

              <StyledTextField
                key={index + 2}
                fullWidth
                disabled={!formActive}
                variant="outlined"
                placeholder={addressFields[2]?.message}
                size="small"
                onChange={(e) =>
                  setFormState((prev) => {
                    let temp = { ...prev };
                    temp[addressFields[2]?.field] = e.target.value;
                    return temp;
                  })
                }
              />
            </Group>
            <Group>
              <StyledTextField
                key={index + 3}
                fullWidth
                disabled={!formActive}
                variant="outlined"
                placeholder={addressFields[3]?.message}
                size="small"
                onChange={(e) =>
                  setFormState((prev) => {
                    let temp = { ...prev };
                    temp[addressFields[3]?.field] = e.target.value;
                    return temp;
                  })
                }
              />

              <StyledTextField
                key={index + 4}
                fullWidth
                disabled={!formActive}
                variant="outlined"
                placeholder={addressFields[4]?.message}
                size="small"
                onChange={(e) =>
                  setFormState((prev) => {
                    let temp = { ...prev };
                    temp[addressFields[4]?.field] = e.target.value;
                    return temp;
                  })
                }
              />
            </Group>
          </>
        ) : data?.type?.toLowerCase() === 'string' ? (
          <StyledTextField
            key={index}
            disabled={!formActive}
            variant="outlined"
            placeholder={data.message}
            size="small"
            fullWidth
            onChange={(e) =>
              setFormState((prev) => {
                let temp = { ...prev };
                temp[data?.field] = e.target.value;
                return temp;
              })
            }
          />
        ) : data?.type?.toLowerCase() === 'email' ? (
          <StyledTextField
            error={emailErrorText.length === 0 ? false : true}
            label={emailErrorText.length === 0 ? null : 'Error'}
            key={index}
            disabled={!formActive}
            variant="outlined"
            placeholder={data.message}
            size="small"
            helperText={emailErrorText}
            fullWidth
            onChange={(e) =>
              setFormState((prev) => {
                let temp = { ...prev };
                temp[data?.field] = e.target.value;
                return temp;
              })
            }
          />
        ) : data?.type?.toLowerCase() === 'phone number' ? (
          <MuiPhoneNumber
            defaultCountry={'us'}
            key={index}
            disabled={!formActive}
            variant="outlined"
            size="small"
            style={{
              backgroundColor: '#FFFFFF',
              fontSize: '14px',
              marginTop: '0.5rem',
              marginBottom: '0.5rem'
            }}
            fullWidth
            onChange={(value) =>
              setFormState((prev) => {
                let temp = { ...prev };
                temp[data?.field] = value;
                return temp;
              })
            }
          />
         ):
          data?.type?.toLowerCase() === 'date' ? (
            <StyledTextField
            key={index}
            disabled={!formActive}
            variant="outlined"
            placeholder={data.message}
            size="small"
            fullWidth
            type="date" 
            onChange={(e) =>
              setFormState((prev) => {
                let temp = { ...prev };
                temp[data?.field] = e.target.value;
                return temp;
              })
            }
          />
        ):data?.type?.toLowerCase() === 'time' ? (
          <StyledTextField
            key={index}
            disabled={!formActive}
            variant="outlined"
            placeholder={data.message}
            size="small"
            fullWidth
            type="time" 
            onChange={(e) => {
              const inputTime = e.target.value;
              const isValid = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(inputTime);
              if (isValid) {
                const [hours, minutes] = inputTime.split(':');
                const hour = parseInt(hours, 10) % 12 || 12;
                const ampm = parseInt(hours, 10) < 12 ? 'AM' : 'PM';
                const formattedTime = `${hour}:${minutes} ${ampm}`;
                setFormState((prev) => ({ ...prev, [data?.field]: formattedTime }));
              }
            }}          
          />
        ):data?.type?.toLowerCase() === 'text' ? (
          <StyledTextField
            key={index}
            disabled={!formActive}
            variant="outlined"
            placeholder={data.message}
            multiline
            rows={5}
            fullWidth
            type='text'
            onChange={(e) =>
              setFormState((prev) => {
                let temp = { ...prev };
                temp[data?.field] = e.target.value;
                return temp;
              })
            }
          />
        ):data?.type?.toLowerCase() === 'number' ? (
          <StyledTextField
            key={index}
            disabled={!formActive}
            variant="outlined"
            placeholder={data.message}
            size="small"
            fullWidth
            type="number" 
            onChange={(e) =>
              setFormState((prev) => {
                let temp = { ...prev };
                temp[data?.field] = e.target.value;
                return temp;
              })
            }
          />
        ) : data?.type?.toLowerCase() === 'boolean' ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <StyledFormLabel key={index}>{data.message}</StyledFormLabel>
            <StyledRadioGroup
              row
              aria-labelledby={index}
              onChange={(e) =>
                setFormState((prev) => {
                  let temp = { ...prev };
                  temp[data?.field] = e.target.value === 'true' ? true : false;
                  return temp;
                })
              }
            >
              <StyledFormControlLabel
                disabled={!formActive}
                value={true}
                control={<Radio size="small" />}
                label={'True'}
              />
              <StyledFormControlLabel
                disabled={!formActive}
                value={false}
                control={<Radio size="small" />}
                label={'False'}
              />
            </StyledRadioGroup>
          </div>
        ) : data?.type?.toLowerCase() === 'multiple' ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <StyledFormLabel key={index}>{data.message}</StyledFormLabel>
            <StyledFormControl size="small" key={index}>
              <Select
                disabled={!formActive}
                key={index}
                defaultValue=""
                value={formState[data?.field]}
                onChange={(e) =>
                  setFormState((prev) => {
                    let temp = { ...prev };
                    temp[data?.field] = e.target.value;
                    return temp;
                  })
                }
              >
                <StyledMenuItem value="" disabled>
                  {data?.message}
                </StyledMenuItem>
                {data?.values?.map((item, index) => (
                  <StyledMenuItem key={index} id={index} value={item}>
                    {item}
                  </StyledMenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </div>
        ):data?.type?.toLowerCase() === 'datetime' ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <StyledFormLabel key={index}>{data.message}</StyledFormLabel>
            <StyledTextField
            key={index}
            disabled={!formActive}
            variant="outlined"
            placeholder={data.message}
            size="small"
            fullWidth 
            onChange={(e) =>
              setFormState((prev) => {
                let temp = { ...prev };
                temp[data?.field] = e.target.value;
                return temp;
              })
            }
          />
          </div>
        ) : (
          <></>
        )
      )}

      {formActive && (
        <Button
          variant="contained"
          startIcon={<SendIcon />}
          sx={{
            textTransform: 'none',
            marginTop: '12px'
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      )}
    </Wrapper>
  );
};

// Exports:
export default ChatForm;
