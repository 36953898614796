import React, { useState, useEffect, memo, useCallback } from "react";
import { Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import RuleBox from "../../RuleBox";
// Styles:
import { FieldName, RuleContainer } from "../styles";
import BaseConfiguration from "../BaseConfiguration";
const ChoiceConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  currentRemoteWorkflow,
  setCurrentRemoteWorkflow,
  setNodes
}) => {
  const [outputFields, setOutputFields] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [choices, setChoices] = useState(node.choices??[]);

  const [ruleOpen, setRuleOpen] = useState(false);
  const [editingRuleIndex, setEditingRuleIndex] = useState(-1);

  useEffect(() => {
    setChoices(node.choices??[]);
  }, [node.choices]);

  useEffect(() => {
    updateNode(node.id, { dynamicFields: dynamicFields, outputFields: outputFields,choices:choices });
  }, [dynamicFields,choices]);

  const handleRuleClose = useCallback(() => {
    setRuleOpen(false);
    setEditingRuleIndex(-1); 
  }, []);

  const handleAddEditRule = useCallback(
    (index) => {
      setRuleOpen(true);
      setEditingRuleIndex(index);
    },
    []
  );    
  
  const handleRuleUpdate = useCallback(
    (updatedRule, index) => {
      const updatedRules = [...choices]; 
      updatedRules[index] = updatedRule; 
      setChoices(updatedRules); 
      updateNode(node.id, { choices: updatedRules }); 
    },
    [choices, updateNode, node.id]
  );

  const renderRuleContainer = (rule) => {
    const { label, branch } = rule;
    return (
      <div>
        Rule Associated with: {branch}
        <br />
        <h4>{label || `will appear here`}</h4>
      </div>
    );
  };

  return (
    <BaseConfiguration
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      dynamicFields={dynamicFields}
      setDynamicFields={setDynamicFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FieldName>Choice</FieldName>
      {choices?.map((rule, index) => (
        <div key={index}>
          <RuleBox
            ruleOpen={ruleOpen && index === editingRuleIndex}
            handleRuleClose={handleRuleClose}
            rule={rule}
            setRules={setChoices}
            rules={choices}
            updateRules={handleRuleUpdate}
            updateNode={updateNode}
            isEditable={isEditable}
            node={node}
            currentRemoteWorkflow={currentRemoteWorkflow}
            setCurrentRemoteWorkflow={setCurrentRemoteWorkflow}
            dynamicFields={dynamicFields}
            index={index}
            outputFields={outputFields}
          />
        </div>
      ))}
      {choices?.map((rule, index) => (
      <RuleContainer >
          <div key={index} >{renderRuleContainer(rule)}</div>
          <div style={{display:'flex', flexDirection:'row',justifyContent:'center' ,gap:'2rem',marginTop:'0.5rem'}}>
          {isEditable && (
              <Tooltip title="Edit Rule">
                <EditIcon onClick={() => handleAddEditRule(index)} sx={{ cursor: 'pointer' }} />
              </Tooltip>
           )}
          </div>
      </RuleContainer>
         ))}
    </BaseConfiguration>
  );
};

export default memo(ChoiceConfiguration);
