// Packages:
import React, { useState, useEffect, memo, useCallback } from "react";
import { Autocomplete, TextField, styled } from "@mui/material";

// Styles:
import { FieldName } from "../styles";
import BaseConfiguration from "../BaseConfiguration";
import { DEFAULT_OUTPUT_FROMEMAIL, DEFAULT_OUTPUT_SUBJECT, DEFAULT_OUTPUT_TOEMAIL } from "./utils";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const EmailConfiguration = ({ node, updateNode, deleteNode, isEditable, setNodes }) => {
  // State:

  const [body,setBody]=useState(node.body??"")
  const [toEmail,setToEmail]=useState(node.toEmail??DEFAULT_OUTPUT_TOEMAIL)
  const [fromEmail,setFromEmail]=useState(node.fromEmail??DEFAULT_OUTPUT_FROMEMAIL)
  const [subject,setSubject]=useState(node.subject??DEFAULT_OUTPUT_SUBJECT)
  const [dynamicDataExpected, setDynamicDataExpected] = useState(node.dynamicDataExpected??false);
  const [numberOfVariables, setNumberOfVariables] = useState( node.numberOfVariables ?? 0);

  const [dynamicData, setDynamicData] = useState(node.dynamicData??[]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields] = useState([])

  // Effects:
  useEffect(() => {
    setToEmail(node.toEmail??DEFAULT_OUTPUT_TOEMAIL)
    setFromEmail(node.fromEmail??DEFAULT_OUTPUT_FROMEMAIL)
    setSubject(node.subject??DEFAULT_OUTPUT_SUBJECT);
    setBody(node.body??"");
    setDynamicDataExpected(node.dynamicDataExpected??false);
    setNumberOfVariables(node.numberOfVariables ?? 0);
    setDynamicData(node.dynamicData ?? []);
  }, [node.toEmail, node.fromEmail,node.subject, node.body, node.dynamicDataExpected,node.numberOfVariables, node.dynamicData]);

  useEffect(() => {
    updateNode(node.id, { toEmail,fromEmail,subject,body, dynamicFields: dynamicFields,outputFields:outputFields, dynamicDataExpected,numberOfVariables,dynamicData});
  }, [toEmail,fromEmail,subject,body, dynamicFields, dynamicDataExpected, numberOfVariables, dynamicData,outputFields]);


  const handleCheckboxChange = useCallback(() => {
    setDynamicDataExpected(!dynamicDataExpected);
    setNumberOfVariables(0);
    setDynamicData([]);
  }, [dynamicDataExpected]);

  const handleNumberOfInputChange = useCallback((e) => {
    const value = parseInt(e.target.value, 10) || 0;
    setNumberOfVariables(value);

    setDynamicData((prevDynamicData) => {
      const newDynamicData = [...prevDynamicData];
      if (value < newDynamicData.length) {
        newDynamicData.length = value;
      } else if (value > newDynamicData.length) {
        for (let i = newDynamicData.length + 1; i <= value; i++) {
          newDynamicData.push({
            key: null,
            value: { fieldName: null, value: null },
          });
        }
      }
      return newDynamicData;
    });
  }, []);

  const handleDataInputChange = useCallback(
    (index, field, value) => {
      if (field !== "key" && !dynamicFields.includes(value)) return;

      setDynamicData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [field === "key" ? "key" : "value"]:
            field === "key"
              ? value
              : { ...newData[index].value, fieldName: value },
        };
        return newData;
      });
    },
    [dynamicFields]
  );

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
    <AutocompleteGlobal Title={"From Email"} dynamicFields={dynamicFields} data={fromEmail} setData={setFromEmail} DEFAULT_OUTPUT={DEFAULT_OUTPUT_FROMEMAIL} isEditable={isEditable} />
    <AutocompleteGlobal Title={"To Email"} dynamicFields={dynamicFields} data={toEmail} setData={setToEmail} DEFAULT_OUTPUT={DEFAULT_OUTPUT_TOEMAIL} isEditable={isEditable} />
    <AutocompleteGlobal Title={"Subject"} dynamicFields={dynamicFields} data={subject} setData={setSubject} DEFAULT_OUTPUT={DEFAULT_OUTPUT_SUBJECT} isEditable={isEditable} />
      <div style={{ width: "100%", height: "1rem" }} />
      <FieldName>Body</FieldName>
      <StyledTextField
        id="email-body"
        variant="outlined"
        disabled={!isEditable}
        placeholder="Write an email..."
        value={body}
        onChange={(e)=>setBody(e.target.value)}
        multiline
        rows={10}
        size="small"
        fullWidth
      />
         <div
              style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
            >
              <input
                type="checkbox"
                style={{ marginRight: "0.35rem" }}
                disabled={!isEditable}
                checked={dynamicDataExpected}
                onChange={handleCheckboxChange}
              />
              <div style={{ fontSize: "0.8rem" }}>dynamic fields expected:</div>
              <input
                type="number"
                min="0"
                disabled={!dynamicDataExpected || !isEditable}
                style={{
                  width: "3rem",
                  marginLeft: "0.25rem",
                }}
                value={numberOfVariables}
                onChange={handleNumberOfInputChange}
              />
            </div>
            {dynamicDataExpected && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {dynamicData.map((_, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <StyledTextField
                type="text"
                placeholder="Variable"
                disabled={!isEditable}
                value={dynamicData[index].key}
                size="small"
                style={{ padding: 0 }}
                onChange={(e) =>
                  handleDataInputChange(index, "key", e.target.value)
                }
              />
              <Autocomplete
                options={dynamicFields}
                disabled={!isEditable}
                value={dynamicData[index].value.fieldName}
                fullWidth
                onChange={(_, value) =>
                  handleDataInputChange(index, "fieldName", value)
                }
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Value"
                    variant="outlined"
                    size="small"
                    style={{ padding: 0 }}
                  />
                )}
              />
            </div>
          ))}
        </div>
      )}
    </BaseConfiguration>
  );
};

// Exports:
export default memo(EmailConfiguration);
