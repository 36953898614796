import styled from 'styled-components';

export const FAQs = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 5%;
  gap: 0.75rem;
  width: 90%;
`;

export const FaqColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 5%;
  padding: 0.5rem 0;
  font-size: 14px;

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;

export const Title = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-weight: bold;
  margin-bottom: 10px;
`;
