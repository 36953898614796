// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  width: 2%;
  height: 98%;
  color: #333333;
  font-size: 0.9rem;
  text-align: center;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  overflow : scroll;
`

export const Title = styled.div`
  padding-top: 1rem;
  padding-left: 1.5rem;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
`

export const Workflow = styled.div`
  padding: 4px 16px;
  padding-bottom: 16px;
  padding-right: 8px;
  background-color: ${ props => props.isFocused ? 'lightskyblue' : 'white' };
  user-select: none;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    background-color: ${ props => props.isFocused ? 'lightskyblue' : 'lightblue' };
  }
`

export const SearchRefreshWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const Name = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  text-align: left;
`

export const LastModified = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #696969;
`

export const CreateNewWorkflow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 50%;
  padding: 1rem;
  font-size: 14px;
  user-select: none;
  cursor: ${ props => props.isActive ? 'pointer' : 'default' };
  filter ${ props => props.isActive ? 'none' : 'greyscale(1)' };
  transition: all 0.25s ease;

  &:hover {
    cursor: pointer;
    background-color: lightgreen;
  }
`
export const LoadMoreButton = styled.div`
  color: white;
  background-color: #2f80ec;
  border: 1px solid #2f80ec;
  border-radius: 5px;
  padding: 0.25rem;
  width: 10rem;
  align-self: center;
  cursor:pointer;
`;

export const ExecutionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  max-height: 500px;
  overflow: scroll;
`;

export const ListContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 1rem;
`
export const IdContainer = styled.p`
  font-weight: bold;
  color: #2f80ec;
  margin-bottom: 0.5rem;
  cursor: pointer;
`
export const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
export const FieldName = styled.div`
  margin-bottom: 0.25rem;
  font-weight: 600;
`
export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 5px;
  padding-right: 10px;
`;