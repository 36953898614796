import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Footer } from "./styles";
import { Button, IconButton, Tooltip } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import ROUTES from "../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { WebSocketConnectionContext } from "../../../App";
import { getContentActions } from "../../../redux/actions/contentActions";
import { useDispatch, useSelector } from "react-redux";
import { startStopWorkflow } from "../../../redux/actions/chatActions";
import { toast } from "react-toastify";
import Scrollbars from "react-custom-scrollbars-2";
import { WORKFLOW_IS_PUBLISHED } from "../../../constants/studioV2";

const WorkflowListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

const WorkflowCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: #f4f4f9;

  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

const VerificationUrlContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.isEnd ? "space-between" : "flex-end")};
`;

export const FeedbackMessage = styled.span`
  font-weight: 600;
`;

const StyledIconButton = styled(IconButton)`
  & .MuiSvgIcon-root {
    @media (max-width: 520px) {
      font-size: 16px;
    }
  }
`;

const WorkflowList = ({ workflows,previewSource }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [likeStatus, setLikeStatus] = useState(0);
  const { sendJsonMessage } = useContext(WebSocketConnectionContext);
  const orgId = localStorage.getItem("org_id");
  const contentActions = getContentActions(dispatch);
  const ticketId = useSelector(
    (state) => state?.content?.workflow?.ticketId
  );

  const [workflowsList, setWorkflowsList] = useState(workflows);

  useEffect(() => {
    setWorkflowsList(workflows);
  }, [workflows]);

  const handleStartWorkflow = (workflow) => {
    dispatch(startStopWorkflow(true));
    const stateMachineMsg = {
      action: "stateMachine",
      orgid: orgId,
      workflowid: workflow?.meta?.id,
      ticketId
    };

    sendJsonMessage(stateMachineMsg);
  };

  const loadWorkflow = (workflow) => {
    const queryParams = {
      workflowId: workflow?.meta?.id,
      status: WORKFLOW_IS_PUBLISHED,
      owner: workflow?.meta?.owner,
    };
    if (!queryParams.workflowId || !queryParams.owner) {
      toast.error("Can not load workflow, Missing paramters!");
      return;
    }
    const queryString = Object.keys(queryParams)
      .map((key) => key + "=" + queryParams[key])
      .join("&");
    navigate(`${ROUTES.AGENT.WORKFLOWS_V2}?${queryString}`, {
      state: {
        isNew: false,
      },
    });
  };

  const handleLikeStatusChange = (workflowIndex, newLikeStatus) => {
    const updatedWorkflow = workflowsList.map((prevWorkflow, index) => {
      if (index === workflowIndex)
        return {
          ...prevWorkflow,
          likeStatus: newLikeStatus,
        };
      else return prevWorkflow;
    });
    setWorkflowsList(updatedWorkflow);
  };

  return (
    <Scrollbars>
      <WorkflowListContainer>
        {workflows?.length > 0 ? (
          workflowsList.map((workflow, index) => (
            <WorkflowCard key={workflow.meta.id}>
              <a
                style={{ fontWeight: "bold" }}
                onClick={() => loadWorkflow(workflow)}
              >
                {workflow.meta.title}
              </a>
              <p>{workflow.description}</p>

              <StyledDiv isEnd={workflow.meta.verification_url !== null}>
                {workflow.meta.verification_url && (
                  <VerificationUrlContainer>
                    <Tooltip
                      title={`${workflow?.meta?.verification_url}`}
                      arrow
                    >
                      <a target="_blank" href={workflow?.meta?.verification_url} >
                        Verification URL
                      </a>
                    </Tooltip>
                  </VerificationUrlContainer>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="medium"
                    startIcon={<SupportAgentOutlinedIcon />}
                    onClick={() => handleStartWorkflow(workflow)}
                    sx={{
                      textTransform: "initial",
                      width: "30%",
                      marginTop: `${workflow.meta.verification_url !== null}`
                        ? "1rem"
                        : "",
                    }}
                  >
                    {`Execute`}
                  </Button>
                  <Footer>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ marginRight: "0.5rem" }}>
                          Was this helpful ?
                        </span>
                        <div>
                          {workflow.likeStatus === 1 ? (
                            <Tooltip title="Undo">
                              <StyledIconButton
                                sx={{ color: "#109648" }}
                                onClick={() => handleLikeStatusChange(index, 0)}
                              >
                                <ThumbUpIcon sx={{ fontSize: 18 }} />
                              </StyledIconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Like">
                              <StyledIconButton
                                onClick={() => handleLikeStatusChange(index, 1)}
                              >
                                <ThumbUpOutlinedIcon sx={{ fontSize: 18 }} />
                              </StyledIconButton>
                            </Tooltip>
                          )}
                          {workflow.likeStatus === -1 ? (
                            <Tooltip title="Undo">
                              <StyledIconButton
                                sx={{ color: "#ED474A" }}
                                onClick={() => handleLikeStatusChange(index, 0)}
                              >
                                <ThumbDownIcon sx={{ fontSize: 18 }} />
                              </StyledIconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Dislike">
                              <StyledIconButton
                                onClick={() =>
                                  handleLikeStatusChange(index, -1)
                                }
                              >
                                <ThumbDownOutlinedIcon sx={{ fontSize: 18 }} />
                              </StyledIconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div style={{ marginLeft: "1rem" }}>
                        <FeedbackMessage
                          style={{
                            color:
                              workflow.likeStatus === 1 ? "#109648" : "#ED474A",
                          }}
                        >
                          {workflow.likeStatus === 1
                            ? "Glad it helped!"
                            : workflow.likeStatus === -1
                            ? "Sorry we couldn't help you."
                            : ""}
                        </FeedbackMessage>
                      </div>
                    </div>
                  </Footer>
                </div>
              </StyledDiv>
            </WorkflowCard>
          ))
        ) : (
          <WorkflowCard>
            Empty Query OR No workflows found for the requested Query !
          </WorkflowCard>
        )}
      </WorkflowListContainer>
    </Scrollbars>
  );
};

export default WorkflowList;
