export const TICKETACTION = {
    FETCH_EVENT_DETAILS:"FETCH EVENT DETAILS",
    FETCH_DATE_TIME_WITH_PRICE : "FETCH DATE TIME WITH PRICE",
    CREATE_REDIRECT_URL : "CREATE REDIRECT URL",
    TRIGGER_EMAIL_CONFIRMATION : "TRIGGER EMAIL CONFIRMATION"
  };

export  const ticketureResponse = {
    "FETCH EVENT DETAILS": ["event_details.date","event_details.start_time","event_details.end_time","event_details.event_found","event_details.location"],
    "FETCH DATE TIME WITH PRICE":["dateTimeGrid"],
    "CREATE REDIRECT URL":["redirectUrl","location"],
    "TRIGGER EMAIL CONFIRMATION": ["emailFound"]
  };

export  const DEFAULT_OUTPUT_BASE_URL = {
    key: "baseurl",
    value: {
      fieldName: null,
      value: null,
    },
  }
  export  const DEFAULT_OUTPUT_LOCATION = {
    key: "location",
    value: {
      fieldName: null,
      value: null,
    },
  } 
  export  const DEFAULT_OUTPUT_DATE = {
    key: "date",
    value: {
      fieldName: null,
      value: null,
    },
  } 
  
  export  const DEFAULT_OUTPUT_DATETIME = {
    key: "dateTime",
    value: {
      fieldName: null,
      value: null,
    },
  } 

  export  const DEFAULT_OUTPUT_EMAIL = {
    key: "email",
    value: {
      fieldName: null,
      value: null,
    },
  } 

  export  const DEFAULT_OUTPUT_DATETIMEGRID = {
    key: "dateTimeGrid",
    value: {
      fieldName: null,
      value: null,
    },
  }  